<template>
    <div class="container">
        <div class="row">
            <div class="card blue-grey darken-1">
                <div class="card-content white-text">
                    <span class="card-title speakerTitle">About the Speaker</span>
                    <div class="crop mr-1">
                        <img src="https://i.pinimg.com/236x/fb/8a/8d/fb8a8defbf55501ce344e161f8c44fed--google-images-gift-ideas.jpg" alt="">
                    </div>
                    <p class="speakerInfo">
                        I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively. I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively. I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                    </p>
                    <div class="speakerWrapper"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>
<style scoped>
    .crop {
        width: 100px;
        height: 100px;
        position: relative;
        overflow: hidden;
        border-radius: 100%;
        float: left;
    }
    
    .speakerTitle {
        font-size: 18px !important;
        font-weight: 700 !important;
        margin-bottom: 20px !important;
    }

    .speakerInfo {
        font-size: 13px;
        line-height: 20px;
    }

    .speakerWrapper {
        clear: both;
    }

    .crop img {
        display: inline;
        margin: 0 auto;
        height: 100%;
        width: auto;
    }
</style>
