<template>
    <div class="navbar-fixed">
        <nav class="mainNavigation" role="navigation">
            <div class="nav-wrapper container">
                <a id="logo-container" href="/" class="brand-logo">
                    <img src="/images/logo.png" />
                </a>
                <ul class="right hide-on-med-and-down navMenu">
                    <li>
                        <router-link :to="{ path: '/' }">HOME</router-link>
                    </li>
                    <li>
                        <router-link :to="{ path: '/' }">ABOUT</router-link>
                    </li>
                    <li>
                        <router-link :to="{ path: '/' }">EVENTS</router-link>
                    </li>
                    <li>
                        <router-link :to="{ path: '/' }">COURSES</router-link>
                    </li>
                    <li v-if="currentUser===null">
                        <router-link :to="{ path: '/login' }">LOGIN</router-link>
                    </li>
                    <li v-if="currentUser===null">
                        <router-link :to="{ path: '/register' }">REGISTER</router-link>
                    </li>
                    <li>
                        <router-link :to="{ path: '/checkout' }" class="logoutBtn">
                            <i class="material-icons">shopping_cart</i>
                            <span class="cart-items">{{totalCartQuantity}}</span>
                        </router-link>
                    </li>
                    
                    <li v-if="currentUser">
                        <a class="dropdown-trigger" href="#!" data-target="dropdown1">
                            <i class="material-icons left mr-0">person</i>
                            <i class="material-icons right ml-0">arrow_drop_down</i>
                        </a>
                    </li>
                </ul>

                <!-- Dropdown Structure -->
                <ul id="dropdown1" class="dropdown-content">
                    <li>
                        <router-link :to="{ path: '/' }"><i class="material-icons mr-0">person</i> Profile</router-link>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a href="#" class="logoutBtn" @click.prevent="logout()">
                            <i class="material-icons mr-0">logout</i> Logout
                        </a>
                    </li>
                </ul>
                
                <ul id="nav-mobile" class="sidenav">
                    <li>
                        <router-link :to="{ path: '/' }">HOME</router-link>
                    </li>
                    <li>
                        <router-link :to="{ path: '/' }">ABOUT</router-link>
                    </li>
                    <li>
                        <router-link :to="{ path: '/' }">EVENTS</router-link>
                    </li>
                    <li>
                        <router-link :to="{ path: '/' }">COURSES</router-link>
                    </li>
                    <li v-if="currentUser===null">
                        <router-link :to="{ path: '/login' }">LOGIN</router-link>
                    </li>
                    <li v-if="currentUser===null">
                        <router-link :to="{ path: '/register' }">REGISTER</router-link>
                    </li>
                    <li>
                        <router-link :to="{ path: '/checkout' }" class="logoutBtn">
                            <i class="material-icons mr-0">shopping_cart</i>
                            <span class="dark">{{totalCartQuantity}}</span>
                        </router-link>
                    </li>
                    <li v-if="currentUser">
                        <a class="dropdown-trigger" href="#!" data-target="dropdown2">
                            <i class="material-icons left">person</i>
                            <i class="material-icons right">arrow_drop_down</i>
                        </a>
                    </li>
                </ul>
                <!-- Dropdown Structure -->
                <ul id="dropdown2" class="dropdown-content">
                    <li>
                        <router-link :to="{ path: '/' }"><i class="material-icons mr-0">person</i> Profile</router-link>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a href="#" class="logoutBtn" @click.prevent="logout()">
                            <i class="material-icons mr-0">logout</i> Logout
                        </a>
                    </li>
                </ul>
                <a href="#" data-target="nav-mobile" class="sidenav-trigger"><i class="material-icons">menu</i></a>
            </div>
        </nav>
    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                getCartInLocalStorage: this.cart ? this.cart : JSON.parse(localStorage.getItem("cart"))
            }
        },

        computed: {
            ...mapGetters({
                isLoggedIn: 'user/isLoggedIn',
                currentUser: 'user/currentUser',
                cart: 'cart/cart',
                totalCartQuantity: 'cart/totalCartQuantity'
            }),
            // ...mapState({
            //     totalCartQuantity: 'cart/cart'
            // })
        },
        created() {
            // this.totalCartQuantity = this.cart.reduce((n, {quantity}) => n + quantity, 0)
        },
        methods: {
            ...mapActions({
                logout: 'user/logout'
            }),
        },
    };
</script>

<style scoped>
    .logoutBtn {
        cursor: pointer;
    }
    .brand-logo {
        margin-top: 10px;
    }

    .navMenu li a {
        font-size: 12px;
    }
    .ml-0 {
        margin-left: 0px !important;
    }
    .mr-0 {
        margin-right: 0px !important;
    }
    .cart-items {
        position: absolute;
        top: 15px;
        right: 5px;
    }
    .mainNavigation {
        height: 80px !important;
        background: #058972;
    }
    .navMenu {
        padding-top: 7px !important;
    }
    
</style>