<template>
    <div id="proceedToPaymentModal" class="modal modal-fixed-footer" dismissible="false">
        <div class="modal-content">
            <div v-if="processCounter==0">
                <div class="row">
                    <div class="col s12">
                        <h5 class="fw-bold">Event Registration Form</h5>
                        <small>
                            By providing your contact details, you consent to our collection, use and disclosure of your personal data as described in our privacy policy on our website. We do strive to limit the amount of personal data we collect to that which is sufficient to support the intended purpose of the collection. Kindly check our <a href="#">Privacy Policy</a>
                        </small>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px !important">
                    <form class="col s12">
                        <div class="row"  style="margin-bottom: 0px !important">
                            <div class="input-field col s3 offset-9">
                                <input id="nirc" type="text" class="validate"  v-model="customer.nirc">
                                <label for="nirc">Last 4-digit of NIRC</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s6">
                                <input id="first_name" type="text" class="validate"  v-model="customer.firstName">
                                <label for="first_name">First Name</label>
                            </div>
                            <div class="input-field col s6">
                                <input id="last_name" type="text" class="validate"  v-model="customer.lastName">
                                <label for="last_name">Last Name</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s6">
                                <!-- <input id="phoneNumber" type="tel" pattern="[0-9]{4}-[0-9]{4}" class="validate"  v-model="customer.phoneNumber"> -->
                                <input id="phoneNumber" type="tel" class="validate"  v-model="customer.phoneNumber">
                                <label for="phoneNumber">Contact Number</label>
                            </div>
                            <div class="input-field col s6">
                                <input id="email" type="email" class="validate"  v-model="customer.email">
                                <label for="email">Email Address</label>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 0px !important">
                            <div class="col s12">
                                <p class="fw-bold">Address</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12" style="margin-bottom: 0px !important">
                                <input id="address" type="text" class="validate" v-model="customer.address">
                                <label for="address">Block No, Street, Bldg Name</label>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 0px !important">
                            <div class="input-field col s12 m4 l4">
                                <input id="city" type="text" class="validate" v-model="customer.city">
                                <label for="city">City</label>
                            </div>
                            <div class="input-field col s12 m4 l4">
                                <input id="postal_code" type="text" class="validate" v-model="customer.postal_code">
                                <label for="postal_code">Postal Code</label>
                            </div>
                            <div class="input-field col s12 m4 l4">
                                <input id="country" type="text" class="validate" v-model="customer.country">
                                <label for="country">Country</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <input id="churchName" type="text" class="validate"  v-model="customer.churchName">
                                <label for="churchName">Name of the Church</label>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 0px !important">
                            <div class="col s12">
                                <p class="fw-bold">Additional Information</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12" style="margin-bottom: 0px !important">
                                <input id="company" type="text" class="validate" v-model="customer.company">
                                <label for="company">Company</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12" style="margin-bottom: 0px !important">
                                <input id="position" type="text" class="validate" v-model="customer.position">
                                <label for="position">Position</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div v-if="processCounter==1">
                <div class="row">
                    <div class="col s12">
                        <h5 class="fw-bold">Confirm Registration</h5>
                    </div>
                </div>
                <table>
                    <tr>
                        <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">NIRC #</td>
                        <td>{{customer.nirc}}</td>
                    </tr>
                    <tr>
                        <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">First Name</td>
                        <td>{{customer.firstName}}</td>
                    </tr>
                    <tr>
                        <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Last Name</td>
                        <td>{{customer.lastName}}</td>
                    </tr>
                    <tr>
                        <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Email Address</td>
                        <td>{{customer.email}}</td>
                    </tr>
                    <tr>
                        <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Phone Number</td>
                        <td>{{customer.phoneNumber}}</td>
                    </tr>
                    <tr>
                        <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Address</td>
                        <td>
                            {{customer.address}} 
                            {{customer.city ? ','+customer.city : '' }} 
                            {{customer.postal_code}} 
                            {{customer.country}}
                        </td>
                    </tr>
                    <tr>
                        <td class="fw-bold" colspan="2">Additional Information</td>
                    </tr>
                    <tr>
                        <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Company</td>
                        <td>
                            {{customer.company}} 
                        </td>
                    </tr>
                    <tr>
                        <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Position</td>
                        <td>
                            {{customer.position}} 
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="processCounter==2">
                <!-- payment portion -->
                <div class="row">
                    <div class="col s12">
                        <h5 class="fw-bold">Select Payment Type</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <ul class="paymentOptionList">
                            <li class="mr-2" v-for="(option, index) in paymentOptions" :key="index">
                                <Popper :hover="true" disableClickAway offsetDistance="-10" offsetSkid="0" placement="bottom">
                                    <img @click="thePayment(option.type)" class="img_style" :src="option.icon_img" :width="option.width" :height="option.height" />
                                    <template #content>
                                        <div style="padding:2px 10px" class="teal lighten-3 white-text">
                                            <small class="text-uppercase fw-bold">
                                                {{option.type}}
                                            </small>
                                        </div>
                                    </template>
                                </Popper>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12 custom-min-height dotted-divider pt-1">
                        <template v-if="selectedpaymentType==='credit card'">
                            <stripe-card-payment></stripe-card-payment>
                        </template>
                        <template v-if="selectedpaymentType==='cash'">
                            <cash-payment></cash-payment>
                        </template>
                        <template v-if="selectedpaymentType==='check'">
                            <check-payment></check-payment>
                        </template>
                        <template v-if="selectedpaymentType==='paynow'">
                            <payNow></payNow>
                        </template>
                    </div>
                </div>
                <div class="row dotted-divider">
                    <div class="col s12">
                        <p class="mb-0 fw-bold">Stay Protected:</p>
                        <small>
                            If you come across an unfamiliar number or even an identity you’re suspicious of, it is always better steer away from any interaction. You should especially be wary of phone numbers shared on open web sources. For instance, phone numbers of especially of food or beverage outlets. It is always best practice to double-check the identity of the person before going ahead with the transaction.
                        </small>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col s12">
                    <a href="#!" class="modal-close waves-effect waves-light btn grey fw-bold cancel_btn">CANCEL</a>
                    <a v-if="processCounter>0" @click="this.processCounter--" href="#!" class="waves-effect waves-light light-blue darken-1 white-text btn fw-bold cancel_btn">
                        BACK
                    </a>
                    <a v-if="processCounter<2" @click="this.processCounter++" href="#!" class="waves-effect waves-light light-blue darken-1 white-text btn fw-bold cancel_btn">
                        NEXT
                    </a>
                    <pay-button v-if="processCounter==2" paymentType="selectedpaymentType"></pay-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Popper from "vue3-popper"
    import stripeCardPayment from './stripeCardPayment'
    import cashPayment from './cashPayment'
    import checkPayment from './checkPayment'
    import payNow from './payNow'
    import PayButton from './payButton'
    import { mapGetters } from 'vuex'
    export default {
        components: {
            Popper,
            stripeCardPayment,
            cashPayment,
            checkPayment,
            payNow,
            PayButton
        },
        data() {
            return {
                customer: {
                    nirc: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    address: '',
                    city: '',
                    postal_code: '',
                    country: '',
                    churchName: '',
                    company: '',
                    position:''
                },
                paymentOptions: [
                    {
                        type: 'cash',
                        icon_img: '/images/cash_payment.png',
                        height: '40',
                        width: '50'
                    },
                    {
                        type: 'credit card',
                        icon_img: '/images/credit_card_logos.png',
                        height: '25',
                        width: '90'
                    },
                    {
                        type: 'check',
                        icon_img: '/images/check_payment.png',
                        height: '30',
                        width: '30'
                    },
                    {
                        type: 'paynow',
                        icon_img: '/images/payNow_logo.png',
                        height: '30',
                        width: ''
                    },
                    
                ],
                selectedpaymentType: 'credit card',
                processCounter: 0,
            }
        },
        computed: {
            ...mapGetters({
                customerCC: 'customer/customerCC'
            })
        },
        methods: {
            payNow() {
                console.log(this.customer)
            },
            thePayment(selectedPayment) {
                this.selectedpaymentType = selectedPayment
            },
        }
    }
</script>
<style scope>
    .modal {
        max-height: 85% !important;
    }
    .modal.modal-fixed-footer {
        height: 85% !important;
    }
    .dotted-divider {
        border-top: 3px dotted rgb(189 188 188);
        padding-top: 10px;
        margin-bottom: 0px !important;
    }
    .card_icons {
        margin-top: 20px;
        margin-left: 10px;
    }
    .payment-form-sub-title {
        opacity: 0.95;
        padding: 15px;
    }
    .cancel_btn {
        margin-right: 10px !important;
    }
    .paymentOptionList li {
        display: inline;
    }
    .paymentOptionList li:hover {
        border-bottom:3px solid #26a69a;
    }
    .img_style {
        cursor: pointer;
    }
    .custom-min-height {
        min-height: 250px;
    }
</style>
