export default {
    namespaced: true,

    state: {
        isLoading: false,
        isEmpty: false,
        isSearching: false,
        handleError: null,
        cart: localStorage.getItem("cart") !== null ? JSON.parse(localStorage.getItem("cart")) : [],
        totalCartQuantity: 0,
    },

    getters: {
        cart(state) {
            return state.cart
        },
        totalCartQuantity(state) {
            return state.totalCartQuantity = state.cart!== null ? state.cart.reduce((n, {quantity}) => n + quantity, 0) : 0
        }
    },

    actions: {
        addToCart({commit, state}, payload) {
            const str = state.cart.find(d => d.id === payload.id)
            if(str) {
                commit('INCREMENT_QNTY_IN_CART_ITEM', str)
            }
            else {
                commit('ADD_TO_CART', payload)
            }
            return true
        },
        updateQuantityInCartCheckout({commit}, item) {
            let qnty = item.quantity
            commit('UPDATE_QNTY_IN_CART_CHECKOUT', {item, qnty})
            return true
        },
        removeItemFromCart({commit}, payload) {
            commit('REMOVE_ITEM', payload)
            return true
        },
        async checkoutCart({commit, state}, payload) {
            if(state.cart.find(d => d.id === payload.id)) {
                return 'exist'
            }
            else {
                await commit('ADD_TO_CART', payload)
                return 'not exist'
            }
        }
    },

    mutations: {
        
        CART(state, payload) {
            state.cart = payload;
        },
        ADD_TO_CART(state, item) {
            item.quantity = 1
            state.cart.push(item)
            localStorage.setItem("cart", JSON.stringify(state.cart))
        },

        INCREMENT_QNTY_IN_CART_ITEM(state, item ) {
            item.quantity++
            localStorage.setItem("cart", JSON.stringify(state.cart))
        },

        UPDATE_QNTY_IN_CART_CHECKOUT(state, {item, qnty} ) {
            const str = state.cart.find(d => d.id === item.id)
            str.quantity = qnty
            localStorage.setItem("cart", JSON.stringify(state.cart))
        },
        REMOVE_ITEM(state, payload) {
            state.cart = payload
            localStorage.setItem("cart", JSON.stringify(state.cart))
        }
    }

}