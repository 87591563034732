<template>
    <Navigation></Navigation>
    <div class="container">
        <div class="row">
            <div class="col s12">
                <h3 class="head-title">Events/Courses</h3>
                <a class="waves-effect waves-light btn right"  @click="addNewEventCourse()">Add</a>
                <table class="responsive-table highlight">
                <thead>
                    <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Schedule</th>
                    <th scope="col">Price</th>
                    <th scope="col">Type</th>
                    <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="isloading">
                        <td colspan="17">
                            <div class="py-4 login100-form-btn center">
                                loading...
                            </div>
                        </td>
                    </tr>
                    <tr v-for="(course) in courses_pagination.data" :key="course.id" v-else>
                        <td>{{course.title}}</td>
                        <td>{{course.schedule}}
                            <span v-for="(entry,index) in course.schedule" :key="index">
                                {{entry.date}}
                            </span>
                        </td>
                        <td>{{course.price}}</td>
                        <td>{{course.type}}</td>
                        <td>
                            <a class="waves-effect waves-light btn-small" @click="editEventCourse(course)"><i class="tiny material-icons">edit</i></a>&nbsp;
                            <a class="waves-effect waves-light btn-small red" @click="deleteEventCourse(course.id)"><i class="tiny material-icons">delete</i></a>
                        </td>
                    </tr>
                </tbody>
                </table>
                <!-- <ul class="pagination" v-if="!isloading">
                    <li class="disabled"><a href="#!"><i class="material-icons">chevron_left</i></a></li>
                    <li class="active"><a href="#!">1</a></li>
                    <li class="waves-effect"><a href="#!">2</a></li>
                    <li class="waves-effect"><a href="#!">3</a></li>
                    <li class="waves-effect"><a href="#!">4</a></li>
                    <li class="waves-effect"><a href="#!">5</a></li>
                    <li class="waves-effect"><a href="#!"><i class="material-icons">chevron_right</i></a></li> 
                </ul> -->
            </div>
        </div>
        <!-- Modal Structure -->
        <div id="editModal" class="modal modal-fixed-footer modal1">
            <div class="modal-content">
                <h5>Update Events/Courses</h5>
                <div class="row">
                    <br>
                    <form class="col s12">
                        <div class="row">
                            <div class="col s12"> 
                                <input v-model="form.id" id="first_name" type="hidden" class="validate">
                                <input placeholder="Title"  v-model="form.title" id="first_name" type="text" class="validate">
                            </div>
                        </div>                        
                        <div class="row">
                            <div class="col s12"> 
                                <input placeholder="Program code"  v-model="form.programCode" id="first_name" type="text" class="validate">
                            </div>
                        </div>                        
                         <div class="row">
                            <i class="small material-icons cursor-icon" @click="add()">add_circle</i> 
                         </div>
                        <div
                            class="row"
                                v-for="(entry, index) in edit_list"
                                :key="index"
                            >
                            <div class="col s6"> 
                                {{entry.date}}
                                <input type="date" v-model="entry.date"  placeholder="Schedule date">
                            </div>
                            <div class="col s5"> 
                                <input type="time" v-model="entry.time" placeholder="Schedule time">
                            </div>
                            <div class="col s1">
                                <i class="small material-icons cursor-icon"  @click="remove(index)"><span>remove_circle</span></i>
                            </div>
                        </div>                                              
                        <div class="row">
                            <div class="col s12"> 
                                <div class="input-field">
                                    <select v-model="form.type">
                                        <option value="" disabled selected>Choose your category</option>
                                        <option value="event">Event</option>
                                        <option value="course">Course</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s12"> 
                                <div class="input-field">
                                    <select v-model="form.type">
                                        <option value="" disabled selected>Choose your type</option>
                                        <option value="event">Event</option>
                                        <option value="course">Course</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s12"> 
                                <input placeholder="Price" v-model="form.price" min="0" id="first_name" type="number" class="validate">
                            </div>
                        </div>  
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat"  @click="submitUpdateEventCourse()">Update</a>
                &nbsp;
                <a class="modal-close waves-effect waves-green btn-flat">Close</a>
            </div>
        </div>
        <div id="addModal" class="modal modal-fixed-footer modal1">
            <div class="modal-content">
            <div class="row">
                <div class="col s12">
                    <h5 class="fw-bold">Events/Courses Form</h5>
                </div>
            </div>
            <div class="row" style="margin-bottom: 0px !important">
                <form class="col s12">
                    <div class="row">
                        <div class="input-field col s6">
                            <input id="first_name" type="text"  v-model="form.title" class="validate">
                            <label for="first_name">Title</label>
                        </div>
                        <div class="input-field col s6">
                            <input id="last_name" type="text"   v-model="form.programCode" class="validate">
                            <label for="last_name">Program Code</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-field col s6">
                            <input id="phoneNumber" type="text"   v-model="form.checkHandler" class="validate">
                            <label for="phoneNumber">Check Handler</label>
                        </div>
                        <div class="input-field col s6">
                            <input id="email" type="email"  v-model="form.email" class="validate">
                            <label for="email">Email Address</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-field col s6">
                            <input id="phoneNumber" type="text"  v-model="form.eventIncharge" class="validate">
                            <label for="phoneNumber">Event Incharge</label>
                        </div>
                        <div class="input-field col s6">
                            <input id="phoneNumber" type="number"  v-model="form.limit" class="validate">
                            <label for="phoneNumber">Limit</label>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="input-field col s6">
                            <input id="phoneNumber" type="number"  v-model="form.price" class="validate">
                            <label for="phoneNumber">Price</label>
                        </div>
                        <div class="input-field col s6">
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 0px !important">
                        <div class="col s6">
                            <p class="dotted-divider fw-bold">Type</p>
                        </div>
                        <div class="col s6">
                            <p class="dotted-divider fw-bold">Public</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s6">
                                <label>
                                    <input name="group2" type="radio"   v-model="form.type" v-bind:value="'event'" checked />
                                    <span>Event</span>
                                </label>
                                 &nbsp;
                                <label>
                                    <input name="group2" type="radio"   v-model="form.type" v-bind:value="'course'" />
                                    <span>Course</span>
                                </label>
                        </div>
                        <div class="col s6">
                                <label>
                                    <input name="group" type="radio"   v-model="form.isPublic" v-bind:value="'1'" checked />
                                    <span>Yes</span>
                                </label>
                                 &nbsp;
                                <label>
                                    <input name="group" type="radio"   v-model="form.isPublic"  v-bind:value="'0'"/>
                                    <span>No</span>
                                </label>
                            </div>
                    </div>

                    <div class="row" style="margin-bottom: 0px !important">
                        <div class="col s12">
                            <p class="dotted-divider fw-bold">Description</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12" style="margin-bottom: 0px !important">
                            <textarea contenteditable="true"   v-model="form.description" ></textarea>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 0px !important">
                        <div class="col s12">
                            <p class="dotted-divider fw-bold">Special Settings</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12" style="margin-bottom: 0px !important">
                            <textarea contenteditable="true"    v-model="form.specialSettings" ></textarea>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 0px !important">
                        <div class="col s12">
                            <p class="dotted-divider fw-bold">Venue Address</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12" style="margin-bottom: 0px !important">
                            <input id="address" type="text" v-model="form.street" class="validate">
                            <label for="address">Block No, Street, Bldg Name</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-field col s12 m4 l4">
                            <input id="city" type="text" v-model="form.city" class="validate">
                            <label for="city">City</label>
                        </div>
                        <div class="input-field col s12 m4 l4">
                            <input id="postal_code" type="text" v-model="form.postalCode" class="validate">
                            <label for="postal_code">Postal Code</label>
                        </div>
                        <div class="input-field col s12 m4 l4">
                            <input id="country" type="text"  v-model="form.country" class="validate">
                            <label for="country">Country</label>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 0px !important">
                        <div class="col s12">
                            <p class="dotted-divider fw-bold">Schedule</p>
                        </div>
                    </div>
                    <div class="row">
                            <i class="small material-icons cursor-icon" @click="add()">add_circle</i> 
                    </div>
                    <div
                        class="row"
                            v-for="(entry, index) in list"
                            :key="index"
                        >
                        <div class="col s5"> 
                            <input type="date" v-model="entry.startDate" placeholder="start date">
                        </div>
                        <div class="col s3"> 
                            <input type="time" v-model="entry.startTime" placeholder="start time">
                        </div>
                        <div class="col s3"> 
                            <input type="time" v-model="entry.endTime" placeholder="end time">
                        </div>
                        <div class="col s1">
                            <i class="small material-icons cursor-icon"  @click="remove(index)"><span>remove_circle</span></i>
                        </div>
                    </div> 

                    <div class="row" style="margin-bottom: 0px !important">
                        <div class="col s12">
                            <p class="dotted-divider fw-bold">Active Until</p>
                        </div>
                    </div>
                    <div class="row">
                            <div class="col s6"> 
                                <input type="date"  v-model="form.activeUntilDate" placeholder="active until date">
                            </div>
                            <div class="col s6"> 
                                <input type="time"  v-model="form.activeUntilTime"  placeholder="active until time">
                            </div>
                    </div>

                    <!-- <div class="row dotted-divider" style="margin-bottom: 0px !important">
                        <div class="col s12">
                            <div class="flex justify-content-left">
                                <p class="fw-bold">Pay secure using credt card</p>
                                <img src="/images/credit_card_logos.png" width="80" height="20" class="card_icons" />
                            </div>
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="input-field col s12">
                            <input id="cardNumber" type="text" class="validate">
                            <label for="cardNumber">Credit Card Number</label>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="input-field col s12 m4 l4">
                            <input id="card_expiry_month" type="text" class="validate">
                            <label for="card_expiry_month">Expiry Month</label>
                        </div>
                        <div class="input-field col s12 m4 l4">
                            <input id="card_expiry_year" type="text" class="validate">
                            <label for="card_expiry_year">Expiry Year</label>
                        </div>
                        <div class="input-field col s12 m4 l4">
                            <input id="cardCvc" type="text" class="validate">
                            <label for="cardCvc">CVV</label>
                        </div>
                    </div> -->
                </form>
            </div>
            <!-- <div class="row">
                <div class="col s12">
                    <p class="mb-0">Note:</p>
                    <small>
                        Test hello world. Test hello worldTest hello worldTest hello worldTest hello worldTest hello worldTest hello world
                    </small>
                </div>
            </div> -->
        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col s12">
                    <a href="#!" class="modal-close waves-effect waves-light btn-small fw-bold cancel_btn">CANCEL</a>
                    <a href="#!" @click.prevent="submitAddEventCourse()" class="blue waves-effect waves-light btn-small fw-bold">ADD NEW</a>
                </div>
            </div>
        </div>
        </div>
        <!-- <div id="addModal1" class="modal modal-fixed-footer modal1">
            <div class="modal-content">
                <h5>Add Events/Courses</h5>
                <div class="row">
                    <br>
                    <form class="col s12">
                         <div class="row">
                            <div class="col s12"> 
                                <input placeholder="Title"  v-model="form.title" type="text" class="validate">
                            </div>
                        </div>                        
                         <div class="row">
                            <div class="col s12"> 
                                <input placeholder="Program code"  v-model="form.programCode" type="text" class="validate">
                            </div>
                        </div>   
                         <div class="row">
                            <i class="small material-icons cursor-icon" @click="add()">add_circle</i> 
                         </div>
                        <div
                            class="row"
                                v-for="(entry, index) in list"
                                :key="index"
                            >
                            <div class="col s6"> 
                                <input type="date" v-model="entry.date" placeholder="Schedule date">
                            </div>
                            <div class="col s5"> 
                                <input type="time" v-model="entry.time" placeholder="Schedule time">
                            </div>
                            <div class="col s1">
                                <i class="small material-icons cursor-icon"  @click="remove(index)"><span>remove_circle</span></i>
                            </div>
                        </div>  
                         <div class="row">
                            <div class="col s12"> 
                                <select v-model="form.type">
                                    <option value="" disabled selected>Choose your category</option>
                                    <option value="event">Event</option>
                                    <option value="course">Course</option>
                                </select>
                            </div>
                        </div>                     
                         <div class="row">
                            <div class="col s12">
                                <label>
                                    <input name="group1" v-model="form.type" type="radio" checked />
                                    <span>Event</span>
                                </label>
                                <label>
                                    <input name="group1" v-model="form.type" type="radio" />
                                    <span>Course</span>
                                </label>
                            </div>
                        </div>                                   
                        <div class="row">
                            <div class="col s12"> 
                                <input placeholder="Price"  min="0" v-model="form.price" type="number" class="validate">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat"  @click="submitAddEventCourse()">Add New</a>
                &nbsp;
                <a class="modal-close waves-effect waves-green btn-flat">Close</a>
            </div>
        </div> -->
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Navigation from './../layout/Navigation';
    import moment from 'moment';
    import { scheduleFormatter } from '../../helper'

    export default {
        components: {
            Navigation,
        },
        mounted: function() {
            // eslint-disable-next-line no-undef
            M.AutoInit();
            // eslint-disable-next-line no-undef
            $('select').formSelect();
        },
        data() {
            return {
                keyword: null,
                isloading: false,
                filter: ['title'],
                order: [10],	
                form: {
                    id:null,
                    title: null,	
                    schedule: null,
                    price: null,	
                    programCode: null,	
                    type: 'event',
                    description: null,
                    checkHandler: null,
                    eventIncharge: null,
                    activeUntil: null,
                    activeUntilDate: null,
                    activeUntilTime: null,
                    venue: null,
                    limit: null,
                    isPublic: '1',
                    email: null,
                    specialSettings: null,
                    country: null,
                    postalCode:null,
                    street:null,
                    city:null
                },
                list:[
                    { startDate: '', startTime: '', endTime: ''}
                ]
            };
        },
        computed: {
            ...mapGetters({
                courses: 'eventcourse/courses',
                handleValidation: 'eventcourse/handleValidation',
                handleError: 'eventcourse/handleError',
                courses_pagination: 'eventcourse/courses_pagination'
            })
        },
        methods: {
             ...mapActions({
                getEventCourse: 'eventcourse/getEventCourse',
                getEventCourseWithPagination: 'eventcourse/getEventCourseWithPagination',
                delEventCourse: 'eventcourse/deleteEventCourse',
                updateEventCourse: 'eventcourse/updateEventCourse',
                addEventCourse: 'eventcourse/addEventCourse',
            }),
        editEventCourse(data) {
            this.edit_list = [];
            this.form.title = data.title? data?.title : '';
            this.form.programCode = data.programCode? data?.programCode : '';
            this.edit_list = data.schedule;
            this.form.price = data.price;
            this.form.type = data.type;
            this.form.id = data.id;
            // eslint-disable-next-line no-undef
            $('#editModal').modal('open');
            // eslint-disable-next-line no-undef
            $('select').formSelect();
        },
        addNewEventCourse() {
            // eslint-disable-next-line no-undef
            $('#addModal').modal('open');
            // eslint-disable-next-line no-undef
            $('select').formSelect();
        },
        submitUpdateEventCourse() {
            this.form.schedule = moment(this.form.schedule).format("MM/D/YYYY, H:mm a");

            this.updateEventCourse(this.form).then(()=> {
                // eslint-disable-next-line no-undef
                $('#editModal').modal('close');
                this.isloading = true;
                this.$toast.show(`Data successfully updated`);
            })
            .finally(() => {
                setTimeout(() => {
                    this.isloading = false;
                     this.getEventCourseWithPagination();
                }, 2000)
            })
        },
        submitAddEventCourse() {
            this.form.schedule = scheduleFormatter(JSON.stringify(this.list));
            const activeUntil = [{
               activeUntilDate: this.form.activeUntilDate,
               activeUntilTime: this.form.activeUntilTime,
            }];
            const venue = [{
               street: this.form.street,
               city: this.form.city,
               country: this.form.country,
               postalCode: this.form.postalCode
            }];
            this.form.activeUntil = scheduleFormatter(JSON.stringify(activeUntil));
            this.form.venue = scheduleFormatter(JSON.stringify(venue));
            this.addEventCourse(this.form).then(()=> {
                if (this.handleError?.status === 422) {
                    this.$toast.error(this.handleError.data.message);
                } else {
                    // eslint-disable-next-line no-undef
                    $('#addModal').modal('close');
                    this.isloading = true;
                    this.$toast.show(`Data successfully added`);
                }
            }).finally(() => {
                setTimeout(() => {
                    this.isloading = false;
                     this.getEventCourseWithPagination();
                }, 2000)
            })
        },
        deleteEventCourse(id) {
            this.delEventCourse(id).then(()=> {
                this.isloading = true;
                 this.$toast.show(`Data successfully deleted`);
            })
            .finally(() => {
                setTimeout(() => {
                    this.isloading = false;
                     this.getEventCourseWithPagination();
                }, 2000)
            })
        },
        add () {
            this.list.push({ date: '', time: ''})
        },
        remove (index) {
            if ( this.list.length < 2 ) {
                return;
            }
            this.list.splice(index, 1)
        } 
        },
        created() {
            this.getEventCourse(this.form)
            .then(()=> {
                this.isloading = true;
            })
            .finally(() => {
                setTimeout(() => {
                    this.isloading = false;
                }, 2000)
            })
            this.getEventCourseWithPagination();
        }
    };
</script>
<style scoped>
    .cursor-icon {
        cursor: pointer;
    }
    input.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
        margin-left: 35px !important;
    }
</style>