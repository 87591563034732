<template>
    <button 
        :disabled='isExistInCart'
        :class="styleClass" 
        href="!#" 
        @click.prevent="clickAddToCart()">
        <small>{{buttonLabel}}</small>
    </button>
</template>

<script>
import { mapActions } from 'vuex'

    export default {

        props: {
            eventCourseData: {
                type: Object,
            },
            styleClass: {
                type: String,
                default: "waves-effect waves-light btn d-block text-uppercase"
            }
        },

        data() {
            return {
                cartItem: [],
                eventCourse: {},
                buttonLabel: 'ADD TO CART',
                isExistInCart: false
            }
        },
        created() {
            if(!localStorage.getItem("cart"))  {
                localStorage.setItem("cart", null)
            }

            if(localStorage.getItem("cart") !== 'null')
            {
                if(JSON.parse(localStorage.getItem("cart")).find(record => record.id === this.eventCourseData.id)) {
                    this.isExistInCart = true
                    this.buttonLabel = 'ADDED TO CART'
                }
            }
        },

        methods: {
            ...mapActions({
                addToCart: 'cart/addToCart'
            }),
            clickAddToCart() {
                this.addToCart(this.eventCourseData)
                    .then(() => {
                        this.$toast.success('Item added to cart successfully');
                        this.isExistInCart = true
                        this.buttonLabel = 'ADDED TO CART'
                    })
            }
        },
 
    }
</script>
<style scoped>
    a {
        color: #fff;
    }
</style>
