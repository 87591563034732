<template>
    <div class="evenCourseCarousel">
        <Carousel v-if="dataHandler.length!==0" :settings="settings" :breakpoints="breakpoints">
            <template v-if="dataHandler">
                <Slide v-for="event in dataHandler" :key="event.id">
                    <div class="carousel__item">
                        <EventCourseCard :eventCourse="event"></EventCourseCard>
                    </div>
                </Slide>
            </template>

            <template v-else>
                <p>
                    loading...
                </p>
            </template>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EventCourseCard from './EventCourseCard'
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const chineseEvents = [
        {
            "id": 1,
            "programCode": "1929",
            "title": "2022年D6 家庭事工大会",
            "slug": "2022年D6-家庭事工大会",
            "description": "Exercitationem qui eos vel eum at quia. Laudantium est et quae labore. Et sed nihil quae debitis Exercitationem qui eos vel eum at quia. Laudantium est et quae labore. Et sed nihil quae debitis Exercitationem qui eos vel eum at quia. Laudantium est et quae labore. Et sed nihil quae debitis Exercitationem qui eos vel eum at quia. Laudantium est et quae labore. Et sed nihil quae debitis.",
            "checkHandler": "Daphnee Schmitt",
            "eventIncharge": "Eriberto Kautzer",
            "schedule": "[{\"startDate\":\"2022-07-29\", \"startTime\":\"08:00\", \"endTime\": \"05:00\"},{\"startDate\":\"2022-07-30\", \"startTime\":\"08:00\", \"endTime\": \"05:00\"}]",
            "activeUntil": "2022-11-24 11:09:15",
            "price": 325,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 411,
            "isPublic": 1,
            "email": "lking@herman.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/uploads/2021/02/2022CD6-poster.jpg",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 1,
                    "name": "Bible",
                    "slug": "bible",
                    "description": "At consequatur quod non voluptas amet quod sint. Asperiores magnam quibusdam nisi quis ea.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 1,
                        "category_id": 1
                    }
                },
                {
                    "id": 2,
                    "name": "Family",
                    "slug": "family",
                    "description": "Quo doloribus et omnis ea quam. Atque mollitia ut nam. Et eum vero assumenda laborum quo.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 1,
                        "category_id": 2
                    }
                }
            ]
        },
        {
            "id": 2,
            "programCode": "3585",
            "title": "职场秘笈系列",
            "slug": "职场秘笈系列",
            "description": "Eum quod modi praesentium. Architecto ut sed praesentium sit eum blanditiis omnis.",
            "checkHandler": "Orlando Steuber",
            "eventIncharge": "Isabella Bruen",
            "schedule": "[{\"startDate\":\"2022-08-13\", \"startTime\":\"09:00\", \"endTime\": \"13:00\"}]",
            "activeUntil": "2022-09-29 16:46:39",
            "price": 673,
            "venue": "{\r\n\t\"location\": \"7 Armenian Street, Bible House - Level 4\",\r\n\t\"city\": \"Singapore\",\r\n\t\"country\": \"SG\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 241,
            "isPublic": 1,
            "email": "deonte61@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/webp-express/webp-images/uploads/2022/05/MicrosoftTeams-image-9.jpg.webp",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 1,
                    "name": "Bible",
                    "slug": "bible",
                    "description": "At consequatur quod non voluptas amet quod sint. Asperiores magnam quibusdam nisi quis ea.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 2,
                        "category_id": 1
                    }
                },
                {
                    "id": 2,
                    "name": "Family",
                    "slug": "family",
                    "description": "Quo doloribus et omnis ea quam. Atque mollitia ut nam. Et eum vero assumenda laborum quo.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 2,
                        "category_id": 2
                    }
                }
            ]
        },
        {
            "id": 3,
            "programCode": "7078",
            "title": "尊荣上帝的领导力: 打破领导的权利迷思",
            "slug": "尊荣上帝的领导力:-打破领导的权利迷思",
            "description": "Consectetur quidem vero aspernatur ut iste fuga excepturi. Est qui accusamus id.",
            "checkHandler": "Ramon Schmitt",
            "eventIncharge": "Garrison Borer",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-07 08:44:01",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 152,
            "isPublic": 1,
            "email": "adubuque@zulauf.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/webp-express/webp-images/uploads/2022/06/leadership-myth-poster.jpg.webp",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 3,
                    "name": "Stewardship",
                    "slug": "stewardship",
                    "description": "Quae dignissimos qui sed labore tenetur. Non est quisquam nihil et eligendi.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 3,
                        "category_id": 3
                    }
                }
            ]
        },
        {
            "id": 4,
            "programCode": "5110",
            "title": "《圣经》书卷品读之《出埃及记》",
            "slug": "《圣经-书卷品读之-《出埃及记》",
            "description": "Odit molestias sunt eum repellat reprehenderit eveniet. Amet iusto maiores voluptatibus et.",
            "checkHandler": "Anastacio Klein I",
            "eventIncharge": "Prof. Odell Carroll Jr.",
            "schedule": "[{\"startDate\":\"2022-07-09\", \"startTime\":\"09:00\", \"endTime\": \"10:00\"}, {\"startDate\":\"2022-07-10\", \"startTime\":\"09:00\", \"endTime\": \"10:00\"}]",
            "activeUntil": "2022-01-07 14:39:05",
            "price": 639,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 220,
            "isPublic": 1,
            "email": "tremblay.holden@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/webp-express/webp-images/uploads/2015/05/Exodus-Poster.jpg.webp",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 4,
                    "name": "Mental Welness",
                    "slug": "mental-wellness",
                    "description": "Pariatur neque earum quam modi omnis. Aspernatur temporibus rerum ullam repellendus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 4,
                        "category_id": 4
                    }
                }
            ]
        },
        {
            "id": 5,
            "programCode": "4645",
            "title": "5相有趣读经法 第五期",
            "slug": "5相有趣读经法 第五期",
            "description": "Eveniet aperiam ad aut excepturi deleniti id amet. Debitis maiores autem illum tempore quis.",
            "checkHandler": "Celine Wisoky",
            "eventIncharge": "Emily Ullrich",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-04 19:00:16",
            "price": 752,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 40,
            "isPublic": 1,
            "email": "bdietrich@lindgren.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/webp-express/webp-images/uploads/2022/06/five-methods-2022-poster.jpg.webp",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 4,
                    "name": "Mental Welness",
                    "slug": "mental-wellness",
                    "description": "Pariatur neque earum quam modi omnis. Aspernatur temporibus rerum ullam repellendus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 5,
                        "category_id": 4
                    }
                }
            ]
        },
        {
            "id": 6,
            "programCode": "2687",
            "title": "Vero facere qui qui.",
            "slug": "vero-facere-qui-qui",
            "description": "Enim qui minus qui beatae ut sit. Ullam provident quo dolor blanditiis dolorem quidem.",
            "checkHandler": "Dr. Lon Gorczany II",
            "eventIncharge": "Eula Reichert II",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-01-16 18:26:52",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 473,
            "isPublic": 1,
            "email": "yquitzon@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 5,
                    "name": "Ministry & Leadership",
                    "slug": "ministry-leadership",
                    "description": "Inventore sed beatae ratione. Adipisci quasi nemo rem non quasi repellat eaque.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 6,
                        "category_id": 5
                    }
                }
            ]
        },
        {
            "id": 7,
            "programCode": "5703",
            "title": "Vero vel nihil est et.",
            "slug": "vero-vel-nihil-est-et",
            "description": "Eligendi sint consequuntur perspiciatis molestias culpa pariatur numquam. Et itaque quam ut.",
            "checkHandler": "Dr. Zola Stoltenberg V",
            "eventIncharge": "Stanford Johns",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-22 21:43:06",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 65,
            "isPublic": 1,
            "email": "alba47@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 6,
                    "name": "Mission",
                    "slug": "mission",
                    "description": "Quia nesciunt nemo sequi. Et id tempore animi. Sed quis vel animi nemo ducimus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 7,
                        "category_id": 6
                    }
                }
            ]
        },
        {
            "id": 8,
            "programCode": "3405",
            "title": "Quam rerum a aperiam.",
            "slug": "quam-rerum-a-aperiam",
            "description": "Eius unde voluptatem distinctio sunt et. Earum quod sequi pariatur. Natus ullam qui iure minima.",
            "checkHandler": "Lizeth Brekke",
            "eventIncharge": "Josue Heaney V",
            "schedule": "[{\"startDate\":\"2022-06-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-16 18:34:26",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 347,
            "isPublic": 1,
            "email": "nbosco@senger.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 6,
                    "name": "Mission",
                    "slug": "mission",
                    "description": "Quia nesciunt nemo sequi. Et id tempore animi. Sed quis vel animi nemo ducimus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 8,
                        "category_id": 6
                    }
                }
            ]
        }
    ]

export default {
    name: 'EventCourseCarousel',
    components: {
        EventCourseCard,
        Carousel,
        Slide,
        Navigation,
    },
    props: {
        eventLanguage: {
            type: String,
            default: 'English'
        },
        eventCategory: {
            type: String,
            default: 'Bible'
        }
    },
    data() {
        return {
             // carousel settings
            settings: {
                itemsToShow: 1,
                itemsToScroll: 1,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                },
            },
            dataHandler: [],
        }
    },

    computed: {
        ...mapGetters({
            eventCourses: 'eventcourse/eventCourses'
        }),
        
    },

    async created() {
        await this.getEventCourse()
        // this.dataHandler = this.eventCourses

        setTimeout(() => {

            if(this.eventLanguage === 'English')
                this.dataHandler = this.eventCourses
            else
                this.dataHandler = chineseEvents //for beta testing only
        }, 1000)
    },

    methods: {
        ...mapActions({
            getEventCourse: 'eventcourse/getEventCourse'
        }),
    },
}
</script>

<style>
    .evenCourseCarousel .carousel__slide {
        padding: 10px;
    }
    .evenCourseCarousel .carousel__prev, .evenCourseCarousel .carousel__next {
        background-color: #26a69a !important;
    }
    .evenCourseCarousel .carousel__prev {
        transform: translate(0%, -50%) !important;
    }
    .evenCourseCarousel .carousel__next {
        transform: translate(0%, -50%) !important;
    }
</style>
