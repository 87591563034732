<template>
    <Navigation></Navigation>
    <div class="pageHeadWrapper">
        <div class="container">
            <div class="categoryWrapper">
                <a v-for="(category, index) in eventCourseProfile.categories" 
                    :key="index"
                    :href="`/category/${category.slug}`"
                    class="fw-bold eventCategory">
                    {{category.name}}
                </a> 
            </div>

            <div class="flex justify-space-between">
                <div class="contentWrapper">
                    <h4 class="white-text fw-bold">
                        {{ eventCourseProfile.title }}
                    </h4>    
                    <p class="white-text fw-100 lh-1">
                        {{ getDescription }}
                    </p>
                    <p class="white-text fw-100 lh-1">
                        <span class="fw-400">
                            Created by : <a href="#">Alliance Of Guest Workers</a>
                        </span>
                    </p>
                    <p class="white-text fw-100 lh-1">
                        <span class="fw-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 14" fill="currentColor">
                                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                            </svg>
                            50 registered
                        </span>
                    </p>
                    <p class="white-text fw-100 lh-1">
                        <span class="fw-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 19" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                            </svg>
                            <!-- 4th floor, The Bible House, Singapore 5643023 -->
                            {{venueData.location}}, {{venueData.city}} {{venueData.postalCode}}, {{venueData.country}}
                        </span>
                    </p>

                    <div class="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 white-text fw-100 lh-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                        </svg>
                        <ul class="mblock-0">
                            <li v-for="(sched, index) in getSchedule" :key="index">
                                <span class="fw-400 white-text">
                                    {{ formatDate(sched.startDate) }} 
                                    {{ sched.startTime }} - 
                                    {{ sched.endTime }}
                                </span>
                            </li>
                        </ul>
                    </div>  
                </div>
                <div class="priceSummary">
                    <div class="summaryContent z-depth-2">
                        <img src="/images/background1.jpg" class="single-event-img" alt="Unsplashed background img 1" />
                        <div class="eventDetail">
                            <h5 class="fw-bold">{{ eventCourseProfile.price > 0 ? priceFormat(eventCourseProfile.price) : 'FREE ADMISSION' }}</h5>
                                <AddToCart :eventCourseData="eventCourseProfile" styleClass="waves-effect waves-light btn d-block text-uppercase btn-large"></AddToCart>
                                <checkoutCart :eventCourseData="eventCourseProfile"></checkoutCart>
                            <!-- <div class="row mb-1">
                                <div class="input-field col s12">
                                    <input placeholder="Apply Promo Code" id="promocode" type="text" class="validate">
                                </div>
                            </div> -->
                            <ul id="group_packages_list" class="collection with-header">
                                <li class="collection-header pinline-0"><p class="fw-bold mb-0">Group Package Promo</p></li>
                                <li class="collection-item pinline-0 pb-0">
                                    <i class="material-icons left">grade</i>
                                    <a href="#!" class="left">5 Group Package</a>
                                    <div class="clearfix"></div>
                                </li>
                                <li class="collection-item pinline-0 pb-0">
                                    <i class="material-icons left">grade</i>
                                    <a href="#!" class="left">10 Group Package</a>
                                    <div class="clearfix"></div>
                                </li>
                                <li class="collection-item pinline-0 pb-0">
                                    <i class="material-icons left">grade</i>
                                    <a href="#!" class="left">15 Group Package</a>
                                    <div class="clearfix"></div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="eventDescription pt-1">
            <h5 class="fw-600">Description</h5>
            {{eventCourseProfile.description}}
        </div>
        <!-- <pre> {{eventCourseProfile}} </pre> -->
    </div>

    <Feedback></Feedback>
    <br />
    <br />
    <SpeakerCard></SpeakerCard>
    <hr />
    <HomepageFooter></HomepageFooter>

</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import Navigation from "./layout/Navigation"
import HomepageFooter from "./layout/Footer"
import SpeakerCard from "./utility/SpeakerCard"
import Feedback from "./utility/Feedback"
import AddToCart from "./utility/AddToCart"
import checkoutCart from "./utility/checkoutCart"
import moment from 'moment'

// created separate helper file for global usage
import { formatPrice, contentStringTrimmer, scheduleFormatter, venueFormatter } from '../helper.js'

export default {
    name: 'Home',
    components: {
        Navigation,
        SpeakerCard,
        Feedback,
        AddToCart,
        checkoutCart,
        HomepageFooter
    },
    data() {
        return {
            getDescription: '',
            getSchedule: '',
            venueData: ''
        }
    },
    async created() {
        await this.profile(this.$route.params.slug)

        if (!Object.keys(this.eventCourseProfile).length) {
            this.$router.push({ path: '/not-found' })
        }
        this.getDescription = contentStringTrimmer(this.eventCourseProfile.description, 250)
        this.getSchedule = scheduleFormatter(this.eventCourseProfile.schedule)
        this.venueData = venueFormatter(this.eventCourseProfile.venue)

    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            eventCourseProfile: 'eventcourse/eventCourse'
        })
    },
    methods: {
        ...mapActions({
            profile: 'eventcourse/profile',
        }),
        formatDate(dataData) {
            return moment(dataData).format('MMM D, YYYY') 
        },
        priceFormat(value) {
            return formatPrice(value)
        },
    }
}

</script>
<style scoped>
    .clearfix {
        clear: both
    }
    .pageHeadWrapper {
        background: #1c1d1f;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .categoryWrapper a, .categoryWrapper span {
        color: #9bebdc;
    } 
    .categoryWrapper a:hover {
        color: #058972;
    } 

    .contentWrapper {
        width: 70%;
        padding-right: 20px;
    }

    .eventCategory {
        margin-right: 8px;
        color: #9bebdc;
        border: 1px solid #fff;
        padding-block: 5px;
        padding-inline: 12px;
        font-size: 11px;
        border-radius: 15px;
    }

    .eventCategory:hover {
        color: #fff;
        background: #9bebdc;
        border: 1px solid #9bebdc;
    }
    
    .priceSummary {
        width: 30%;
    }
    .summaryContent {
        background: #fff;
        border: 1px solid #fff;
        position: absolute;
        width: 21%;
        z-index: 1;
    }
    .single-event-img {
        width: 100%;
        background-position: center;
        object-fit: contain;
        object-position: center;
    }
    .eventDetail {
        padding-inline: 25px;
        padding-bottom: 50px;
    }

    .checkout_btn {
        border: 1px solid #d9d9d9;
    }

    .checkout_btn:hover {
        background: #b4b4b4 !important;
        border: 1px solid #b4b4b4 !important;
        color: #fff !important;
    }

    .eventDescription {
        width: 70%;
        min-height: 300px;
    }

    .eventDescription p {
        font-size: 16px;
        line-height: 27px;
    }
    .eventDescription ol > li {
        padding-block: 15px;
    }
    .eventDescription ul  {
        list-style: square !important;
    }
    #group_packages_list {
        border: none !important;
    }
    #group_packages_list .collection-item,
    #group_packages_list .collection-header {
        border-bottom: none !important;
    } 
</style>