<template>
    <Navigation></Navigation>
    <BannerCarousel></BannerCarousel>
    <div class="container programmeSection">
        <h4 class="teal-text darker-2 center">Latest Programmes</h4>
        <div class="eventCategories">
            <p class="catheader">Event Categories : </p>
            <p v-for="(category, index) in categories" :key="index" class="categoryItem" @click="selectCategory(category.name)">{{category.name}}</p>
        </div>
        <!-- Dropdown Trigger -->
        <div class="center programmeCatBtn">
            <a class='dropdown-trigger btn' href='#' data-target='categoriesDropdown'>Event Categories</a>
        </div>

        <!-- Dropdown Structure -->
        <ul id='categoriesDropdown' class='dropdown-content'>
            <li v-for="(category, index) in categories" :key="index"  @click="selectCategory(category.name)"><a href="#!">{{category.name}}</a></li>
        </ul>
    </div>
    <section class="englishSectionWrapper">
        <div class="container">
            <div class="englishProgrammeWrapper">
                <h5 class="teal-text darken-2 fw-bold mt-0">Sower Institute - English Programmes</h5>
                <router-link class="grey-text darker-3 fw-500" :to="{ path: '/register' }">
                    <span>
                        See All English Programmes
                        <i class="material-icons" style="vertical-align:middle;">chevron_right</i>
                    </span>
                </router-link>
            </div>
            <EventCourseCarousel eventCategory="Bible" eventLanguage="English"></EventCourseCarousel>
        </div>
    </section>

    <div class="container programmeSection pt-1">
        <div class="eventCategories">
            <p class="catheader">活动类别: </p>
            <p class="categoryItem">线上课程</p>
            <p class="categoryItem">线下课程</p>
            <p class="categoryItem">认识圣经</p>
            <p class="categoryItem">卓越领袖</p>
            <p class="categoryItem">智慧沟通</p>
            <p class="categoryItem">婚姻家庭</p>
            <p class="categoryItem">事工推动</p>
            <p class="categoryItem">属灵操练</p>
            <p class="categoryItem">健康心灵</p>
        </div>
        <!-- Dropdown Trigger -->
        <div class="center programmeCatBtn">
            <a class='dropdown-trigger btn' href='#' data-target='chineseCatDropdown'>活动类别</a>
        </div>

        <!-- Dropdown Structure -->
        <ul id='chineseCatDropdown' class='dropdown-content'>
            <li><a href="#!">线上课程</a></li>
            <li><a href="#!">线下课程</a></li>
            <li><a href="#!">认识圣经</a></li>
            <li><a href="#!">卓越领袖</a></li>
            <li><a href="#!">智慧沟通</a></li>
            <li><a href="#!">婚姻家庭</a></li>
            <li><a href="#!">事工推动</a></li>
            <li><a href="#!">属灵操练</a></li>
            <li><a href="#!">健康心灵</a></li>
            <li><a href="#!">健康心灵</a></li>
        </ul>
    </div>
    <section class="chineseSectionWrapper">
        <div class="container">
            <div class="chineseProgrammeWrapper">
                <h5 class="teal-text darken-2 fw-bold mt-0">Sower Institute - <span style="font-weigh:200 !important;">英语课程</span></h5>
                <router-link class="grey-text darker-3 fw-500" :to="{ path: '/register' }">
                    <span>
                        查看所有中文课程
                        <i class="material-icons" style="vertical-align:middle;">chevron_right</i>
                    </span>
                </router-link>
            </div>
            <EventCourseCarousel eventCategory="Bible" eventLanguage="Chinese"></EventCourseCarousel>
        </div>
    </section>

    <section class="partnership">
        <div class="row">
            <div class="left-section col s12 m9 l6">
                <div class="box-content">
                    <p class="header text-white fw-400 mblock-2">OUR PARTNERSHIP</p>
                    <p class="content text-white fw-600">
                        We offer a wide range of programmes to equip the people of God
                    </p>
                    <p class="sub-content text-white">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                        diam nonummy nibh euismod tincidunt ut laoreet dolore
                        magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                        quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                        aliquip ex ea commodo consequat. Duis
                    </p>

                    <button class="btn mblock-1 newsletter_btn text-uppercase">Join Our Newsletter</button>
                </div>
            </div>
            <div class="col s6">
                <div class="box-content2">
                    <div class="row">
                        <div class="s12">
                            <img src="images/logos.png" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s3 center pblock-0">
                            <a href="#!" style="color:#9b8e8e;">AND MORE</a>
                        </div>
                        <div class="col s7 center">
                            <button class="btn btn-medium amber waves-effect waves-light newsletter_btn d-block m-0 darken-2">
                                CONTACT US FOR NEW PARTNERSHIP
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>

    <section class="mobileViewPartnership">
        <div class="row center">
            <div class="col s12 greenTheme">
                <p class="header text-white fw-400 mblock-2">OUR PARTNERSHIP</p>
                <h4 class="content text-white fw-600">
                    We offer a wide range of programmes to equip the people of God
                </h4>
                <p class="sub-content text-white">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                    diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                    aliquip ex ea commodo consequat. Duis
                </p>

                <button class="btn mblock-1 newsletter_btn text-uppercase">Join Our Newsletter</button>
            </div>
            <div class="col s12">
                <div class="row center">
                    <div class="s12">
                        <img src="images/logos.png" width="100%" />
                    </div>
                </div>
                <div class="flex justify-space-between">
                    <div class="center pblock-0">
                        <a href="#!" style="color:#9b8e8e;">AND MORE</a>
                    </div>
                    <div class="center">
                        <button class="btn btn-medium amber waves-effect waves-light newsletter_btn d-block m-0 darken-2">
                            CONTACT US FOR NEW PARTNERSHIP
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="about">
        <div class="row">
            <div class="col s12 m9 l6">
                <div class="video-holder">
                    <img src="images/video-dummy.png" class="z-depth-3" width="600" />
                </div>
            </div>
            <div class="right-section col s6">
                <div class="box-content">
                    <p class="header text-white fw-400 mblock-2">ABOUT US</p>
                    <p class="content text-white fw-600">
                        About Sower Institute for Biblical Discipleship (SIBD)
                    </p>
                    <p class="sub-content text-white">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                        diam nonummy nibh euismod tincidunt ut laoreet dolore
                        magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                        quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                        aliquip ex ea commodo consequat. Duis
                    </p>

                    <button class="btn mblock-1 newsletter_btn text-uppercase">Click here to more details</button>
                </div>
            </div>
        </div>
    </section>

    <section class="mobileViewAbout">
        <div class="flex-block">
            <div class="center greenTheme">
                <p class="header text-white fw-400 mblock-2">ABOUT US</p>
                <h4 class="content text-white fw-600">
                    About Sower Institute for Biblical Discipleship (SIBD)
                </h4>
                <p class="sub-content text-white">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                    diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                    aliquip ex ea commodo consequat. Duis
                </p>

                <button class="btn mblock-1 newsletter_btn text-uppercase">Click here to more details</button>
            </div>
            <div class="about-video-holder">
                <img src="images/video-dummy.png" class="z-depth-3" width="100%" />
            </div>
        </div>
    </section>
    <HomepageFooter></HomepageFooter>
</template>

<script>

import { mapGetters } from 'vuex';
import Navigation from "./layout/Navigation"
import BannerCarousel from "./utility/BannerCarousel"
import EventCourseCarousel from "./utility/EventCourseCarousel"
import HomepageFooter from "./layout/Footer"

export default {
    name: 'Home',
    components: {
        Navigation,
        BannerCarousel,
        EventCourseCarousel,
        HomepageFooter
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            cart: 'cart/cart'
        }),
        // ...mapState({
        //     selectedCategory: 'eventcourse/selectedCategory'
        // })
    },
    data() {
        return {
            categories: [
                { name: 'Recommendations' },
                { name: 'Bible' },
                { name: 'Family' },
                { name: 'Stewardship' },
                { name: 'Mental Wellness' },
                { name: 'Ministry & Leadership' },
                { name: 'Mission' },
            ],
            selectedCategory: null
        }
    },
    methods: {
        selectCategory(categoryName) {
            // this.selectedCategory = category
            console.log(categoryName);
        }
    }
}

</script>

<style scoped>
    .mobileViewPartnership .greenTheme {
        background: #058972;
        padding-block: 20px;
    }
    .mobileViewPartnership, .mobileViewAbout {
        display: none;
    }

    .mobileViewAbout .about-video-holder {
        background: #c59640;
        padding: 25px;
    }

    .mobileViewAbout .greenTheme {
        background: #058972;
        padding-block: 2px;
    }

    .about {
        background: #c59640;
        padding-block: 50px;
    }
    .about .right-section {
        background: #058972;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        height: 480px;
    }
    .about .box-content {
        width: 500px;
        padding-left: 120px;
        float: left;
    }

    .about .video-holder {
        float: right;
        padding-top: 70px;
        position: absolute;
        left: 210px;
    }

    .about .box-content .header {
        opacity: 0.80;
        font-size: 12px;
    }
    .about .box-content .content {
        font-size: 30px;
        line-height: 35px;
        font-weight: bolder;
        opacity: 0.90;
    }
    .about .box-content .sub-content {
        font-size: 14px;
        line-height: 19px;
        opacity: 0.70;
    }
    .about .box-content .newsletter_btn {
        background: #fff;
        color: #009688;
        font-size: 15px;
        padding-inline: 40px;
    }
    .partnership .left-section {
        background: #058972;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        height: 500px;
        margin-left: -45px !important;
        -webkit-transform: skew(-10deg);
            -moz-transform: skew(-10deg);
            -o-transform: skew(-10deg);
    }

    .partnership .box-content {
        position: initial;
        width: 430px;
        padding-right: 50px;
        float: right;
        -webkit-transform: skew(10deg);
            -moz-transform: skew(10deg);
            -o-transform: skew(10deg);
    }

    .partnership .box-content .header {
        opacity: 0.80;
        font-size: 12px;
    }
    .partnership .box-content .content {
        font-size: 30px;
        line-height: 35px;
        font-weight: bolder;
        opacity: 0.90;
    }
    .partnership .box-content .sub-content {
        font-size: 14px;
        line-height: 19px;
        opacity: 0.70;
    }
    .partnership .box-content .newsletter_btn {
        background: #fff;
        color: #009688;
        font-size: 15px;
        padding-inline: 40px;
    }

    .partnership .box-content2 {
        padding: 40px;
    }

    .programmeSection h4 {
        font-weight: 600;
    }
    .eventCategories {
        display: flex;
        justify-content: space-between;
        height: 65px;
    }
    .categoryItem {
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        color: rgb(149 143 143);
    }
    .categoryItem:hover {
        color: #009688 !important;
        border-bottom: 3px solid #009688;
    }
    .catheader {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        color: rgb(149 143 143);
    }
    .programmeCatBtn {
        display: none;
    }

    /* English Program section */
    .englishSectionWrapper {
        padding-block: 50px;
        background: rgb(247 247 247);
    }
    .englishProgrammeWrapper {
        display: flex;
        justify-content: space-between;
    }
    .englishProgrammeWrapper a > span:hover {
        color: #444;
    }

    /* chinese Program section */
    .chineseSectionWrapper {
        padding-block: 50px;
    }
    .chineseProgrammeWrapper {
        display: flex;
        justify-content: space-between;
    }
    .chineseProgrammeWrapper a span[data-v-8dc7cce2]:hover {
        color: #444;
    }
</style>