import EventCourses from "@/api/EventCourse";

export default {
    namespaced: true,

    state: {

        handleValidation: [],
        isLoading: false,
        isEmpty: false,
        isSearching: false,
        handleError: null,
        eventCourses: [],
        eventCourse: {},
        courses_pagination: [],
    },

    getters: {

        eventCourses(state) {
            return state.eventCourses
        },
        eventCourse(state) {
            return state.eventCourse
        },
        courses_pagination(state) {
            return state.courses_pagination
        },
        handleError(state) {
            return state.handleError
        },
        handleValidation(state) {
            return state.handleValidation
        },
    },

    actions: {
        
        getEventCourse({commit}, payload) {

            return EventCourses.get(payload)
                .then((response) => {
                    // commit('EVENTCOURSES', response.data) // from the backend DB
                    commit('EVENTCOURSES', response)
                })
                .catch((error) => {
                    commit('HANDLE_ERROR', error.response)
                })
            
        },
        profile({commit}, payload) {
            return EventCourses.profile(payload)
                .then((response) => {
                    // commit('EVENTCOURSE', response.data) // from the backend DB
                    commit('EVENTCOURSE', response)
                })
                .catch((error) => {
                    commit('HANDLE_ERROR', error.response)
                })
        },
        getEventCourseWithPagination({commit}) {

            return EventCourses.getAllWithPagination()
                .then((response) => {
                    commit('EVENTCOURSEPAGINATION', response.data)
                })
                .catch((error) => {
                    commit('HANDLE_ERROR', error.response)
                })
            
        },
    
        addEventCourse({commit}, payload) {
            EventCourses.add(payload)
                .then((response) => {
                    commit('EVENTCOURSE', response.data)
                })
                .catch((errors) => {
                    commit('HANDLE_ERROR', errors.response)
                })
        },

        updateEventCourse({commit}, payload) {
    
            EventCourses.update(payload)
                .then((response) => {
                    commit('EVENTCOURSE', response.data)
                })
                .catch((errors) => {
                    commit('HANDLE_ERROR', errors.response)
                })
        },
        deleteEventCourse({commit}, payload) {
    
            EventCourses.delete(payload)
                .then((response) => {
                    commit('EVENTCOURSE', response.data)
                })
                .catch((errors) => {
                    commit('HANDLE_ERROR', errors.response)
                })
        },
    
    },

    mutations: {
        HANDLE_VALIDATION(state, payload) {
            state.handleValidation = payload.data.errors;
        },
    
        HANDLE_ERROR(state, payload) {
            state.handleError = payload;
        },

        EVENTCOURSES(state, eventCoursesData) {
            state.eventCourses = eventCoursesData;
        },
        EVENTCOURSE(state, eventCourseData) {
            state.eventCourse = eventCourseData;
        },
        
        EVENTCOURSEPAGINATION(state, eventCoursesData) {
            state.courses_pagination = eventCoursesData;
        },
    }

}