<template>
    <Navigation></Navigation>
    <div class="row justify-content-center mt-5" style="min-height: 500px !important;">
        <div class="col-md-9">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <h1>Profile</h1>
                    <form @submit.prevent="add()" class="login100-form validate-form">
                        <div class="wrap-input100 validate-input">
                            <input type="hidden" v-model="form.id" class="input100"/>
                            <input type="email" v-model="form.email" class="input100" placeholder="Email" id="email" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.niceName" class="input100" placeholder="Nicename" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.programCode" class="input100" placeholder="programCode" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.firstName" class="input100" placeholder="Firstname" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.lastName" class="input100" placeholder="Lastname" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.nirc" class="input100" placeholder="Nirc" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.phoneNumber" class="input100" placeholder="Phone number" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.address" class="input100" placeholder="Address" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.city" class="input100" placeholder="City" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.postalCode" class="input100" placeholder="Postal code" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.country" class="input100" placeholder="Country" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.church" class="input100" placeholder="Church" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.billingAddress" class="input100" placeholder="Billing address" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.ShippingAddress" class="input100" placeholder="Shipping address" />
                        </div>
                        <div class="wrap-input100 validate-input">
                            <input type="text" v-model="form.address" class="input100" placeholder="Address" />
                        </div>
                        <div class="container-login100-form-btn py-4">
                            <button type="submit" class="login100-form-btn btn-primary" :disabled="loadingLoggedIn" :class="{ 'disabled-btn': loadingLoggedIn }">
                                <span v-if="!loadingLoggedIn">Update profile</span>
                                loading...
                            </button>
                        </div>
                    </form>
                </div>
            </div>
      </div>
    </div>
</template>

<script>

    import { mapActions, mapGetters, mapState } from 'vuex';
    import Navigation from '../layout/Navigation'

    export default {
        components: {
            Navigation,
        },
        data() {
            return {
                keyword: null,
                form: {
                    id: null,
                    // niceName: null,
                    email: null,
                    programCode: null,
                    // firstName: null,
                    // lastName: null,
                    // nirc: null,
                    // phoneNumber: null,
                    // address: null,
                    // city: null,
                    // postalCode: null,
                    // country: null,
                    // church: null,
                    // billingAddress: null,
                    // ShippingAddress: null,
                    // userType: 'Guest',
                    // status: 0
                },
                loadingLoggedIn: false
            };
        },

        computed: {
            ...mapGetters({
                currentUser: 'user/currentUser'
            }),
            ...mapState({
                users: 'user/users',
            }),
        },

        methods: {
             ...mapActions({
                getUsers: 'user/getUsers',
                addUser: 'user/addUser',
                addEventCourse: 'eventcourse/addEventCourse',
                updateUser: 'user/updateUser',
                delUser: 'user/delUser',
            }),
            
            add() {
                 this.$store.dispatch('eventcourse/addEventCourse', this.form)
                .then((res) => {
                    console.log(res);
                })
                .finally(() => {
                    this.loadingLoggedIn = false;
                })
            },
            
            update() {
                this.updateUser(this.form)
                .then((res) => {
                    console.log(res);
                })
                .finally(() => {
                    this.loadingLoggedIn = false;
                })
            },
            
            del() {
                let user = {
                    name: 'John Doe',
                    email: 'test@ecample.com',
                    password: '1234'
                }

                this.delUser(user);
            }
        },

        async created() {
            const resultData = await this.getUsers();
            this.usersList = resultData.data;
            const userData = this.usersList.filter(item => item.id == this.$route.params.id);
            this.form.id = userData[0].id;
            this.form.email = userData[0].email;
            this.form.niceName = userData[0].niceName;
            this.form.username = userData[0].username;
            this.form.firstName = userData[0].firstName;
            this.form.lastName = userData[0].lastName;
            this.form.nirc = userData[0].nirc;
            this.form.phoneNumber = userData[0].phoneNumber;
            this.form.address = userData[0].address;
            this.form.city = userData[0].city;
            this.form.postalCode = userData[0].postalCode;
            this.form.country = userData[0].country;
            this.form.church = userData[0].church;
            this.form.billingAddress = userData[0].billingAddress;
            this.form.ShippingAddress = userData[0].ShippingAddress;
            this.form.userType = userData[0].userType;
            this.form.status = userData[0].status;
        },

    };

</script>
<style scoped>
    
    .btn-custom-style {
        background: #dc3545;
        color: #fff;
    }
    .btn-custom-style:hover {
        opacity: 0.87;
        color: #fff;
    }
    .customBorder {
        border-top: 1px solid #dfdfdf !important;
        border-left: 1px solid #dfdfdf !important;
        border-right: 1px solid #dfdfdf !important;
        font-weight: bold;
    }
    .disabled-btn {
        background: #292728 !important;
    }

    .login100-form .text-danger {
        margin-left: 20px !important;
    }

    .error_login {
        border-bottom: 1px solid #dc3545 !important;
    }

    a {
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #dc3545;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}


/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #555555;}
input:-moz-placeholder { color: #555555;}
input::-moz-placeholder { color: #555555;}
input:-ms-input-placeholder { color: #555555;}

textarea::-webkit-input-placeholder { color: #555555;}
textarea:-moz-placeholder { color: #555555;}
textarea::-moz-placeholder { color: #555555;}
textarea:-ms-input-placeholder { color: #555555;}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    background-image: url('/images/login_bg.jpg');
    width: 100%;  
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;  
}

.container-login100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.65);
}

.wrap-login100 {
    width: 390px;
    border-radius: 10px;
    overflow: hidden;
    background: transparent;
}


/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
}

.login100-form-logo {
    font-size: 60px; 
    color: #333333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto;
}

.login100-form-title {
    font-size: 28px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    display: block;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    padding: 29px 0;
}

.input100 {
    font-size: 20px;
    color: #555555;
    line-height: 1.2;

    display: block;
    width: 100%;
    height: 50px;
    background: transparent;
    padding: 0 10px 0 40px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}


.input100:focus {
    padding-left: 60px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
    width: 100%;
    padding-inline: 35px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: bolder;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 160px;
    height: 50px;
    border-radius: 0px;

    background: #dc3545;

    position: relative;
    z-index: 1;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 21px;
    background-color: #555555;
    top: 0;
    left: 0;
    opacity: 0;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background: #b32230;
}


</style>