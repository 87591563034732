<template>
    <a href="#!" @click.prevent="payNow()" class="blue waves-effect waves-light btn fw-bold">PAY NOW</a>
</template>
<script>

export default {
    name: 'pay-button',
    props: {
        paymentType: {
            type: String
        },
        paymentDetails: {
            type: Object
        },
        customer: {
            type: Object
        },
        cartItems: {
            type: Array
        }
    }
}
</script>
