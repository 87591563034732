import { createRouter, createWebHistory } from 'vue-router'
// import Home from "@/components/Home.vue";
import Login from "@/components/pages/Login.vue";
import Register from "@/components/pages/Register.vue";
import Home from "@/components/Home.vue";

import Dashboard from "@/components/Dashboard.vue";
// import EventCourse from "@/components/EventCourse.vue";
import Profile from "@/components/pages/Profile.vue";
import User from '@/components/admin/User.vue';
// import Events from '@/components/admin/Event.vue';
import EventSingleView from '@/components/EventSingleView.vue';

// import Course from '@/components/admin/Course.vue';
// import Profile from "@/components/Profile.vue";
// import User from '@/components/admin/User.vue';
import EventCourse from '@/components/admin/EventCourse.vue';
import addEventCourse from '@/components/admin/addEventCourse.vue';
import Checkout from '@/components/Checkout.vue';
import NotFound from '@/components/pages/404.vue';
import Categories from '@/components/admin/Categories.vue';

const routes = [
    
    /** ==================================== */
    /*  Authenticated routes for Admin */ 
    /** ==================================== */
    {
        path:'/dashboard',
        name: 'Admin Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true },
    },
    {
        path: '/user',
        name: 'User',
        component: User,
        meta: { requiresAuth: true },
    },
    {
        path: '/event-course',
        name: 'Event',
        component: EventCourse,
        meta: { requiresAuth: true },
    },
    {
        path: '/add-event-course',
        name: 'Event',
        component: addEventCourse,
        meta: { requiresAuth: true },
    },
    {
        path: '/categories',
        name: 'Categories',
        component: Categories,
        meta: { requiresAuth: true },
    },
    {
        path: '/profile/:id',
        name: 'Admin Profile',
        component: Profile,
        meta: { requiresAuth: true },
    },
    {
        path: '/event-course/:id',
        name: 'Event Course',
        component: EventCourse,
        meta: { requiresAuth: true },
    },

    /** ==================================== */
    /*  Non-Authenticated routes for all     */ 
    /** ==================================== */

    {
        path: '/',
        name: '/',
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/event/:slug',
        name: 'EventSingleView',
        component: EventSingleView,
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
    },
    {
        path: '/not-found',
        name: '404 Page',
        component: NotFound,
    },
];

const router = createRouter({
    mode: "history",
    baseurl: process.env.BASE_URL,
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;