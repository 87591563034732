<template>
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="wrapper">
                <div class="inner">
                    <div class="image-holder">
                        <img src="images/register.jpeg" alt="">
                    </div>
                    <form @submit.prevent="register()">
                        <h3 class="fw-bolder">Create An Account</h3>

                        <div class="form-wrapper">
                            <input type="text" v-model="form.firstname" class="form-control" placeholder="First Name" />
                            <small class="text-danger" v-if="errors.firstname">{{ errors.firstname[0] }}</small>
                        </div>
                        <div class="form-wrapper">
                            <input type="text" v-model="form.lastname" class="form-control" placeholder="Last Name" />
                            <small class="text-danger" v-if="errors.lastname">{{ errors.lastname[0] }}</small>
                        </div>
                        <div class="form-wrapper">
                            <input type="text" v-model="form.nice_name" class="form-control" placeholder="Nice Name" />
                            <small class="text-danger" v-if="errors.nice_name">{{ errors.nice_name[0] }}</small>
                        </div>
                        <div class="form-wrapper">
                            <input type="email" v-model="form.email" class="form-control" placeholder="Email Address" />
                            <small class="text-danger" v-if="errors.email">{{ errors.email[0] }}</small>
                        </div>
                        <div class="form-wrapper">
                            <input type="password" v-model="form.password" class="form-control" placeholder="Password" />
                            <small class="text-danger" v-if="errors.password">{{ errors.password[0] }}</small>
                        </div>
                        <div class="form-wrapper">
                            <input type="password" v-model="form.password_confirmation" class="form-control" placeholder="Confirm Password" />
                            <small class="text-danger" v-if="errors.password_confirmation">{{ errors.password_confirmation[0] }}</small>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" v-model="form.usertype" class="form-check-input">
                                    <label class="form-check-label">
                                        <small>Create my account as an employer</small>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" id="exampleCheck1" v-model="form.isAgree" class="form-check-input">
                                    <label for="exampleCheck1" class="form-check-label">
                                        <small>I agree and comply to the <a href="/privacy-policy">Privacy Policy</a></small>
                                    </label>
                                </div>
                                <small class="text-danger" v-if="agreeError">{{agreeError}}</small>
                            </div>
                        <div class="row mt-2">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <button type="submit" class="rounded-0 btn btn-register  btn-block" :disabled="!form.isAgree">REGISTER</button>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <button type="reset" class="rounded-0 btn btn-dark btn-block ml-1">RESET</button>
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from "../../api/User"

export default {

    data() {
        return {
            form: {
                usertype: false, //false = applicant | true = employer_user
                firstname: null,
                lastname: null,
                nice_name: null,
                email: null,
                password: null,
                password_confirmation: null,
                isAgree: false,
            },
            errors: [],
            agreeError: null
        };
    },

    methods: {
        register() {

            User.register(this.form)
                .then((response) => {

                    if(response.data.isAgreeError) {
                        this.agreeError = response.data.isAgreeError;
                    } else {
                        this.$router.push({ name: "Login" });
                    }
                })
                .catch(error => {
                    if(error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
        }
    }

};
</script>
<style scoped>
    .wrapper {
        background: #000 url('/images/register_bg.jpeg') no-repeat center top;
    }

    .form-check-input {
        border-radius: 0px !important;
    }

    .form-check-label {
        font-size: 13px !important;
    }

    .form-check .form-check-input {
        margin-top: 7px !important;
    }

    .inner {
        opacity: 0.86 !important;
    }
    
    .btn-register {
        background: #dc3545 !important;
    }

    .inner h3 {
        margin-bottom: 45px !important;
    }

    .inner form {
        padding-top: 10px !important;
    }

    .form-wrapper {
       margin-bottom: 30px !important; 
    }

    .form-wrapper .form-control {
        border: 1px solod #adb5bd !important;
        margin-bottom: 0px !important;
    }

    .form-wrapper small {
        font-size: 10px !important;
    }

    input, textarea, select, button {
    color: #333;
    font-size: 13px; }

    p, h1, h2, h3, h4, h5, h6, ul {
    margin: 0; }

    img {
    max-width: 100%; }

    ul {
    padding-left: 0;
    margin-bottom: 0; }

    a:hover {
    text-decoration: none; }

    :focus {
    outline: none; }

    .wrapper {
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center; }

    .inner {
    padding: 20px;
    background: #fff;
    max-width: 850px;
    margin: auto;
    display: flex; }
    .inner .image-holder {
        width: 50%; }
    .inner form {
        width: 50%;
        padding-top: 36px;
        padding-left: 45px;
        padding-right: 45px; }
    .inner h3 {
        text-transform: uppercase;
        font-size: 25px;
        text-align: center;
        margin-bottom: 28px; }

    .form-group {
    display: flex; }
    .form-group input {
        width: 50%; }
        .form-group input:first-child {
        margin-right: 25px; }

    .form-wrapper {
    position: relative; }
    .form-wrapper i {
        position: absolute;
        bottom: 9px;
        right: 0; }

    .form-control {
    border: 1px solid #333;
    border-top: none;
    border-right: none;
    border-left: none;
    display: block;
    width: 100%;
    height: 30px;
    padding: 0;
    margin-bottom: 25px; }
    .form-control::-webkit-input-placeholder {
        font-size: 13px;
        color: #333; 
    }
    .form-control::-moz-placeholder {
        font-size: 13px;
        color: #333; 
    }
    .form-control:-ms-input-placeholder {
        font-size: 13px;
        color: #333; 
    }
    .form-control:-moz-placeholder {
        font-size: 13px;
        color: #333; 
    }

    select {
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    padding-left: 20px; }
    select option[value=""][disabled] {
        display: none; }

    button {
    border: none;
    width: 164px;
    height: 51px;
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #333;
    font-size: 15px;
    color: #fff;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s; }
    button i {
        margin-left: 10px;
        -webkit-transform: translateZ(0);
        transform: translateZ(0); }
    button:hover i, button:focus i, button:active i {
        -webkit-animation-name: hvr-icon-wobble-horizontal;
        animation-name: hvr-icon-wobble-horizontal;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1; }

    @-webkit-keyframes hvr-icon-wobble-horizontal {
    16.65% {
        -webkit-transform: translateX(6px);
        transform: translateX(6px); }
    33.3% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px); }
    49.95% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px); }
    66.6% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px); }
    83.25% {
        -webkit-transform: translateX(1px);
        transform: translateX(1px); }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    @keyframes hvr-icon-wobble-horizontal {
    16.65% {
        -webkit-transform: translateX(6px);
        transform: translateX(6px); }
    33.3% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px); }
    49.95% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px); }
    66.6% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px); }
    83.25% {
        -webkit-transform: translateX(1px);
        transform: translateX(1px); }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
    @media (max-width: 1199px) {
    .wrapper {
        background-position: right center; } }
    @media (max-width: 991px) {
    .inner form {
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 30px; } }
    @media (max-width: 767px) {
    .inner {
        display: block; }
        .inner .image-holder {
        width: 100%; }
        .inner form {
        width: 100%;
        padding: 40px 0 30px; }

    button {
        margin-top: 60px; } }

</style>