import Api from "./Api";
// import Csrf from "./Csrf";

const eventCourses = [
        {
            "id": 1,
            "programCode": "1929",
            "title": "D6 Family Conference 2022",
            "slug": "d6-family-conference-2022",
            "description": "Exercitationem qui eos vel eum at quia. Laudantium est et quae labore. Et sed nihil quae debitis Exercitationem qui eos vel eum at quia. Laudantium est et quae labore. Et sed nihil quae debitis Exercitationem qui eos vel eum at quia. Laudantium est et quae labore. Et sed nihil quae debitis Exercitationem qui eos vel eum at quia. Laudantium est et quae labore. Et sed nihil quae debitis.",
            "checkHandler": "Daphnee Schmitt",
            "eventIncharge": "Eriberto Kautzer",
            "schedule": "[{\"startDate\":\"2022-07-29\", \"startTime\":\"08:00\", \"endTime\": \"05:00\"},{\"startDate\":\"2022-07-30\", \"startTime\":\"08:00\", \"endTime\": \"05:00\"}]",
            "activeUntil": "2022-11-24 11:09:15",
            "price": 325,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 411,
            "isPublic": 1,
            "email": "lking@herman.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/webp-express/webp-images/uploads/2021/02/D62022-poster.jpg.webp",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 1,
                    "name": "Bible",
                    "slug": "bible",
                    "description": "At consequatur quod non voluptas amet quod sint. Asperiores magnam quibusdam nisi quis ea.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 1,
                        "category_id": 1
                    }
                },
                {
                    "id": 2,
                    "name": "Family",
                    "slug": "family",
                    "description": "Quo doloribus et omnis ea quam. Atque mollitia ut nam. Et eum vero assumenda laborum quo.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 1,
                        "category_id": 2
                    }
                }
            ]
        },
        {
            "id": 2,
            "programCode": "3585",
            "title": "Miracle of Life Change",
            "slug": "miracle-of-life-change",
            "description": "Eum quod modi praesentium. Architecto ut sed praesentium sit eum blanditiis omnis.",
            "checkHandler": "Orlando Steuber",
            "eventIncharge": "Isabella Bruen",
            "schedule": "[{\"startDate\":\"2022-08-13\", \"startTime\":\"09:00\", \"endTime\": \"13:00\"}]",
            "activeUntil": "2022-09-29 16:46:39",
            "price": 673,
            "venue": "{\r\n\t\"location\": \"7 Armenian Street, Bible House - Level 4\",\r\n\t\"city\": \"Singapore\",\r\n\t\"country\": \"SG\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 241,
            "isPublic": 1,
            "email": "deonte61@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/webp-express/webp-images/uploads/2022/06/Miracle-of-Life-Change-Poster.jpg.webp",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 1,
                    "name": "Bible",
                    "slug": "bible",
                    "description": "At consequatur quod non voluptas amet quod sint. Asperiores magnam quibusdam nisi quis ea.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 2,
                        "category_id": 1
                    }
                },
                {
                    "id": 2,
                    "name": "Family",
                    "slug": "family",
                    "description": "Quo doloribus et omnis ea quam. Atque mollitia ut nam. Et eum vero assumenda laborum quo.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 2,
                        "category_id": 2
                    }
                }
            ]
        },
        {
            "id": 3,
            "programCode": "7078",
            "title": "Come Play with Me",
            "slug": "come-play-with-me",
            "description": "Consectetur quidem vero aspernatur ut iste fuga excepturi. Est qui accusamus id.",
            "checkHandler": "Ramon Schmitt",
            "eventIncharge": "Garrison Borer",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-07 08:44:01",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 152,
            "isPublic": 1,
            "email": "adubuque@zulauf.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/webp-express/webp-images/uploads/2015/05/2022-Nextgen-Poster.jpeg.webp",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 3,
                    "name": "Stewardship",
                    "slug": "stewardship",
                    "description": "Quae dignissimos qui sed labore tenetur. Non est quisquam nihil et eligendi.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 3,
                        "category_id": 3
                    }
                }
            ]
        },
        {
            "id": 4,
            "programCode": "5110",
            "title": "Effective Coaching and Mentoring",
            "slug": "effective-coaching-and-mentoring",
            "description": "Odit molestias sunt eum repellat reprehenderit eveniet. Amet iusto maiores voluptatibus et.",
            "checkHandler": "Anastacio Klein I",
            "eventIncharge": "Prof. Odell Carroll Jr.",
            "schedule": "[{\"startDate\":\"2022-07-09\", \"startTime\":\"09:00\", \"endTime\": \"10:00\"}, {\"startDate\":\"2022-07-10\", \"startTime\":\"09:00\", \"endTime\": \"10:00\"}]",
            "activeUntil": "2022-01-07 14:39:05",
            "price": 639,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 220,
            "isPublic": 1,
            "email": "tremblay.holden@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://www.bible.org.sg/wp-content/webp-express/webp-images/uploads/2015/05/SIBD-DECM-Poster-FA.jpg.webp",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 4,
                    "name": "Mental Welness",
                    "slug": "mental-wellness",
                    "description": "Pariatur neque earum quam modi omnis. Aspernatur temporibus rerum ullam repellendus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 4,
                        "category_id": 4
                    }
                }
            ]
        },
        {
            "id": 5,
            "programCode": "4645",
            "title": "Et autem sunt sunt.",
            "slug": "et-autem-sunt-sunt",
            "description": "Eveniet aperiam ad aut excepturi deleniti id amet. Debitis maiores autem illum tempore quis.",
            "checkHandler": "Celine Wisoky",
            "eventIncharge": "Emily Ullrich",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-04 19:00:16",
            "price": 752,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 40,
            "isPublic": 1,
            "email": "bdietrich@lindgren.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 4,
                    "name": "Mental Welness",
                    "slug": "mental-wellness",
                    "description": "Pariatur neque earum quam modi omnis. Aspernatur temporibus rerum ullam repellendus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 5,
                        "category_id": 4
                    }
                }
            ]
        },
        {
            "id": 6,
            "programCode": "2687",
            "title": "Vero facere qui qui.",
            "slug": "vero-facere-qui-qui",
            "description": "Enim qui minus qui beatae ut sit. Ullam provident quo dolor blanditiis dolorem quidem.",
            "checkHandler": "Dr. Lon Gorczany II",
            "eventIncharge": "Eula Reichert II",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-01-16 18:26:52",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 473,
            "isPublic": 1,
            "email": "yquitzon@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 5,
                    "name": "Ministry & Leadership",
                    "slug": "ministry-leadership",
                    "description": "Inventore sed beatae ratione. Adipisci quasi nemo rem non quasi repellat eaque.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 6,
                        "category_id": 5
                    }
                }
            ]
        },
        {
            "id": 7,
            "programCode": "5703",
            "title": "Vero vel nihil est et.",
            "slug": "vero-vel-nihil-est-et",
            "description": "Eligendi sint consequuntur perspiciatis molestias culpa pariatur numquam. Et itaque quam ut.",
            "checkHandler": "Dr. Zola Stoltenberg V",
            "eventIncharge": "Stanford Johns",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-22 21:43:06",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 65,
            "isPublic": 1,
            "email": "alba47@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 6,
                    "name": "Mission",
                    "slug": "mission",
                    "description": "Quia nesciunt nemo sequi. Et id tempore animi. Sed quis vel animi nemo ducimus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 7,
                        "category_id": 6
                    }
                }
            ]
        },
        {
            "id": 8,
            "programCode": "3405",
            "title": "Quam rerum a aperiam.",
            "slug": "quam-rerum-a-aperiam",
            "description": "Eius unde voluptatem distinctio sunt et. Earum quod sequi pariatur. Natus ullam qui iure minima.",
            "checkHandler": "Lizeth Brekke",
            "eventIncharge": "Josue Heaney V",
            "schedule": "[{\"startDate\":\"2022-06-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-16 18:34:26",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 347,
            "isPublic": 1,
            "email": "nbosco@senger.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 6,
                    "name": "Mission",
                    "slug": "mission",
                    "description": "Quia nesciunt nemo sequi. Et id tempore animi. Sed quis vel animi nemo ducimus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 8,
                        "category_id": 6
                    }
                }
            ]
        },
        {
            "id": 9,
            "programCode": "7420",
            "title": "Non ut quis qui aut.",
            "slug": "non-ut-quis-qui-aut",
            "description": "Nihil et provident eius. Eligendi harum officia et eligendi. Pariatur omnis id est harum beatae.",
            "checkHandler": "Ms. Eudora Toy",
            "eventIncharge": "Rhianna Feeney",
            "schedule": "[{\"startDate\":\"2022-01-29\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-14 06:46:43",
            "price": 183,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 317,
            "isPublic": 1,
            "email": "gbarrows@cormier.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Charity",
                    "slug": "charity",
                    "description": "Vel itaque dolorum a voluptatem ea nihil. Voluptatibus minima vel non culpa ex.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 9,
                        "category_id": 7
                    }
                }
            ]
        },
        {
            "id": 10,
            "programCode": "6505",
            "title": "In saepe totam velit in.",
            "slug": "in-saepe-totam-velit-in",
            "description": "Aliquid tempore praesentium et porro quos facilis eos et. Sed repellendus adipisci sed sit quaerat.",
            "checkHandler": "Cathryn Metz",
            "eventIncharge": "Yoshiko Stroman Sr.",
            "schedule": "[{\"startDate\":\"2022-09-19\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-05-16 22:46:41",
            "price": 0,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 462,
            "isPublic": 1,
            "email": "joey.feeney@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Charity",
                    "slug": "charity",
                    "description": "Vel itaque dolorum a voluptatem ea nihil. Voluptatibus minima vel non culpa ex.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 10,
                        "category_id": 7
                    }
                }
            ]
        },
        {
            "id": 11,
            "programCode": "2623",
            "title": "Eos harum nobis et illo.",
            "slug": "eos-harum-nobis-et-illo",
            "description": "Laboriosam totam tempore molestias voluptatem mollitia sit. Saepe distinctio eos possimus harum.",
            "checkHandler": "Miss Rosemary Heidenreich",
            "eventIncharge": "Mr. Taurean Bosco II",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-10-15 05:09:42",
            "price": 308,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 113,
            "isPublic": 1,
            "email": "mraz.abe@ryan.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Charity",
                    "slug": "charity",
                    "description": "Vel itaque dolorum a voluptatem ea nihil. Voluptatibus minima vel non culpa ex.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 11,
                        "category_id": 7
                    }
                }
            ]
        },
        {
            "id": 12,
            "programCode": "4786",
            "title": "Ex neque quae dolorem.",
            "slug": "ex-neque-quae-dolorem",
            "description": "Explicabo unde quam odit sed. Sunt animi excepturi et qui recusandae.",
            "checkHandler": "Mylene Schaefer",
            "eventIncharge": "Miss River Kuvalis Jr.",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-09-27 11:52:37",
            "price": 358,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 334,
            "isPublic": 1,
            "email": "runte.dallas@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 8,
                    "name": "Information Technology",
                    "slug": "information-technology",
                    "description": "Minima et temporibus vel consectetur eum. Aperiam sint culpa nemo corporis aut assumenda.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 12,
                        "category_id": 8
                    }
                }
            ]
        },
        {
            "id": 13,
            "programCode": "6197",
            "title": "Ullam sed sit quod quia.",
            "slug": "ullam-sed-sit-quod-quia",
            "description": "Quod est ipsum voluptatem ad et modi. Eligendi illo esse dolorem quia placeat ut quod.",
            "checkHandler": "Dr. Johnnie Glover",
            "eventIncharge": "Elyssa Funk",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-10-20 10:30:32",
            "price": 591,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 219,
            "isPublic": 1,
            "email": "gabrielle.cruickshank@fadel.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 8,
                    "name": "Information Technology",
                    "slug": "information-technology",
                    "description": "Minima et temporibus vel consectetur eum. Aperiam sint culpa nemo corporis aut assumenda.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 13,
                        "category_id": 8
                    }
                }
            ]
        },
        {
            "id": 14,
            "programCode": "4229",
            "title": "Qui quas aut in esse.",
            "slug": "qui-quas-aut-in-esse",
            "description": "Sequi cum omnis illum at quibusdam vel facere. Architecto perspiciatis nemo quia qui id.",
            "checkHandler": "Prof. Olen Mueller III",
            "eventIncharge": "Reta Hegmann",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-01-15 17:55:57",
            "price": 856,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 401,
            "isPublic": 1,
            "email": "xwisoky@parisian.org",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 9,
                    "name": "Esse",
                    "slug": "esse",
                    "description": "Eaque quia ipsum enim natus eaque natus. Sed ut ut et est magnam dolorem sint.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 14,
                        "category_id": 9
                    }
                }
            ]
        },
        {
            "id": 15,
            "programCode": "1201",
            "title": "Sunt aut laudantium qui.",
            "slug": "sunt-aut-laudantium-qui",
            "description": "Qui tempora reiciendis vel sapiente. Dolores aliquam nesciunt eos ullam cumque.",
            "checkHandler": "Prof. Brianne Torphy",
            "eventIncharge": "Vito Borer",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-02-12 23:48:35",
            "price": 238,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 210,
            "isPublic": 1,
            "email": "will.cordelia@nolan.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 1,
                    "name": "Bible",
                    "slug": "bible",
                    "description": "At consequatur quod non voluptas amet quod sint. Asperiores magnam quibusdam nisi quis ea.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 15,
                        "category_id": 1
                    }
                },
                {
                    "id": 10,
                    "name": "Eum Ipsam",
                    "slug": "eum-ipsam",
                    "description": "Error dolorem aliquam non commodi ut odio. Aut neque maxime qui. Vel molestiae aut quia.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 15,
                        "category_id": 10
                    }
                }
            ]
        },
        {
            "id": 16,
            "programCode": "1937",
            "title": "Harum cum et eaque.",
            "slug": "harum-cum-et-eaque",
            "description": "Sint quaerat necessitatibus consectetur odio. Minus sunt qui rerum provident dolorum pariatur.",
            "checkHandler": "Leonor Schultz",
            "eventIncharge": "Bobby Barton III",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-02-04 09:44:57",
            "price": 297,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 97,
            "isPublic": 1,
            "email": "lisette96@maggio.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 10,
                    "name": "Eum Ipsam",
                    "slug": "eum-ipsam",
                    "description": "Error dolorem aliquam non commodi ut odio. Aut neque maxime qui. Vel molestiae aut quia.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 16,
                        "category_id": 10
                    }
                }
            ]
        },
        {
            "id": 17,
            "programCode": "7790",
            "title": "Quae sed quas et ut.",
            "slug": "quae-sed-quas-et-ut",
            "description": "Et nam nisi explicabo eum quia. Et aut et asperiores.",
            "checkHandler": "Nicola Kerluke",
            "eventIncharge": "Dr. Serenity Hodkiewicz",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-11-18 05:59:57",
            "price": 738,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 58,
            "isPublic": 1,
            "email": "dhauck@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 6,
                    "name": "Mission",
                    "slug": "mission",
                    "description": "Quia nesciunt nemo sequi. Et id tempore animi. Sed quis vel animi nemo ducimus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 17,
                        "category_id": 6
                    }
                }
            ]
        },
        {
            "id": 18,
            "programCode": "8987",
            "title": "Rerum nobis quod quos.",
            "slug": "rerum-nobis-quod-quos",
            "description": "Quidem omnis aut ut. Ullam aut officiis voluptatibus delectus. Quo qui sed deleniti occaecati.",
            "checkHandler": "Prof. Abdullah Larson",
            "eventIncharge": "Dr. Weston Prosacco DVM",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-10-08 05:08:21",
            "price": 592,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 57,
            "isPublic": 1,
            "email": "leuschke.jeremie@orn.biz",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Charity",
                    "slug": "charity",
                    "description": "Vel itaque dolorum a voluptatem ea nihil. Voluptatibus minima vel non culpa ex.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 18,
                        "category_id": 7
                    }
                }
            ]
        },
        {
            "id": 19,
            "programCode": "7586",
            "title": "Ratione nam fuga culpa.",
            "slug": "ratione-nam-fuga-culpa",
            "description": "Ipsam sed quis doloribus autem et. Rerum minus placeat autem sed quas exercitationem accusamus.",
            "checkHandler": "Prof. Emely Casper",
            "eventIncharge": "Otto Kertzmann",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-08-03 23:23:42",
            "price": 682,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 278,
            "isPublic": 1,
            "email": "gloria.jenkins@corwin.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 9,
                    "name": "Esse",
                    "slug": "esse",
                    "description": "Eaque quia ipsum enim natus eaque natus. Sed ut ut et est magnam dolorem sint.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 19,
                        "category_id": 9
                    }
                }
            ]
        },
        {
            "id": 20,
            "programCode": "4227",
            "title": "Consequatur et sit odit.",
            "slug": "consequatur-et-sit-odit",
            "description": "Deserunt voluptate dolorem quia tempora. Magni doloribus ducimus repudiandae dolor quaerat fugit.",
            "checkHandler": "Merle Krajcik",
            "eventIncharge": "Cynthia Powlowski Sr.",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-07-09 13:20:07",
            "price": 270,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 219,
            "isPublic": 1,
            "email": "swaniawski.lavada@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 9,
                    "name": "Esse",
                    "slug": "esse",
                    "description": "Eaque quia ipsum enim natus eaque natus. Sed ut ut et est magnam dolorem sint.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 20,
                        "category_id": 9
                    }
                }
            ]
        },
        {
            "id": 21,
            "programCode": "6778",
            "title": "Qui sit maiores quod.",
            "slug": "qui-sit-maiores-quod",
            "description": "Suscipit error quia maxime nihil qui rerum qui. Labore numquam ullam autem dolorem.",
            "checkHandler": "Earnest Wehner IV",
            "eventIncharge": "Mikayla Pfannerstill",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-10-02 02:33:04",
            "price": 816,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 405,
            "isPublic": 1,
            "email": "ybins@gmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 2,
                    "name": "Family",
                    "slug": "family",
                    "description": "Quo doloribus et omnis ea quam. Atque mollitia ut nam. Et eum vero assumenda laborum quo.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 21,
                        "category_id": 2
                    }
                }
            ]
        },
        {
            "id": 22,
            "programCode": "4730",
            "title": "Aliquid esse ad tenetur.",
            "slug": "aliquid-esse-ad-tenetur",
            "description": "Aut est praesentium quaerat dolorem. Quisquam non maxime commodi odit.",
            "checkHandler": "Mr. Dante Spencer",
            "eventIncharge": "Mr. Ignatius Waelchi",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-29 03:14:25",
            "price": 610,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 446,
            "isPublic": 1,
            "email": "fhayes@langworth.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 2,
                    "name": "Family",
                    "slug": "family",
                    "description": "Quo doloribus et omnis ea quam. Atque mollitia ut nam. Et eum vero assumenda laborum quo.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 22,
                        "category_id": 2
                    }
                }
            ]
        },
        {
            "id": 23,
            "programCode": "5968",
            "title": "Magni illum dicta a.",
            "slug": "magni-illum-dicta-a",
            "description": "Qui minima laudantium ullam laborum id dolorum. Dolor veritatis deserunt veniam.",
            "checkHandler": "Kianna Toy",
            "eventIncharge": "Elnora Kuhlman",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-04-19 04:27:04",
            "price": 848,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 415,
            "isPublic": 1,
            "email": "genoveva06@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 3,
                    "name": "Stewardship",
                    "slug": "stewardship",
                    "description": "Quae dignissimos qui sed labore tenetur. Non est quisquam nihil et eligendi.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 23,
                        "category_id": 3
                    }
                }
            ]
        },
        {
            "id": 24,
            "programCode": "1069",
            "title": "Sed animi amet non.",
            "slug": "sed-animi-amet-non",
            "description": "Omnis nulla repellendus magnam dolor. Laboriosam amet ipsum fugit dolore quis.",
            "checkHandler": "Jamaal Torphy",
            "eventIncharge": "Margaret Schroeder",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-01-24 22:36:22",
            "price": 431,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 374,
            "isPublic": 1,
            "email": "bryana24@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 1,
                    "name": "Bible",
                    "slug": "bible",
                    "description": "At consequatur quod non voluptas amet quod sint. Asperiores magnam quibusdam nisi quis ea.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 24,
                        "category_id": 1
                    }
                },
                {
                    "id": 4,
                    "name": "Mental Welness",
                    "slug": "mental-wellness",
                    "description": "Pariatur neque earum quam modi omnis. Aspernatur temporibus rerum ullam repellendus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 24,
                        "category_id": 4
                    }
                }
            ]
        },
        {
            "id": 25,
            "programCode": "5148",
            "title": "Non ducimus sit rem qui.",
            "slug": "non-ducimus-sit-rem-qui",
            "description": "Maxime odit et accusantium omnis distinctio. Hic autem sint cum eaque.",
            "checkHandler": "Mr. Alphonso Raynor",
            "eventIncharge": "Katarina Collier",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-05-04 10:50:39",
            "price": 814,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 23,
            "isPublic": 1,
            "email": "herman.lueilwitz@kerluke.org",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 4,
                    "name": "Mental Welness",
                    "slug": "mental-wellness",
                    "description": "Pariatur neque earum quam modi omnis. Aspernatur temporibus rerum ullam repellendus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 25,
                        "category_id": 4
                    }
                }
            ]
        },
        {
            "id": 26,
            "programCode": "8052",
            "title": "Ad dolor culpa est esse.",
            "slug": "ad-dolor-culpa-est-esse",
            "description": "Dolor vel consequatur dolor nesciunt facere rerum. A est ab voluptatem quo qui laboriosam.",
            "checkHandler": "Tara Kassulke",
            "eventIncharge": "Clifton Dickens",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-09-18 23:25:43",
            "price": 464,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 474,
            "isPublic": 1,
            "email": "rowe.viva@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 5,
                    "name": "Ministry & Leadership",
                    "slug": "ministry-leadership",
                    "description": "Inventore sed beatae ratione. Adipisci quasi nemo rem non quasi repellat eaque.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 26,
                        "category_id": 5
                    }
                }
            ]
        },
        {
            "id": 27,
            "programCode": "3914",
            "title": "Maxime est iste ea.",
            "slug": "maxime-est-iste-ea",
            "description": "Voluptatem omnis qui est doloribus id incidunt. Non animi ipsam dolorem nemo possimus ea ducimus.",
            "checkHandler": "Electa Grady",
            "eventIncharge": "Elody Mraz I",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-15 23:47:15",
            "price": 786,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 422,
            "isPublic": 1,
            "email": "jazmyn81@mayert.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 6,
                    "name": "Mission",
                    "slug": "mission",
                    "description": "Quia nesciunt nemo sequi. Et id tempore animi. Sed quis vel animi nemo ducimus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 27,
                        "category_id": 6
                    }
                }
            ]
        },
        {
            "id": 28,
            "programCode": "6337",
            "title": "Nisi ipsa eum rerum.",
            "slug": "nisi-ipsa-eum-rerum",
            "description": "Voluptatem fugiat itaque qui quisquam accusamus voluptas soluta. Qui voluptatem qui temporibus.",
            "checkHandler": "Ervin Sanford",
            "eventIncharge": "Dereck McGlynn",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-15 01:04:56",
            "price": 173,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 132,
            "isPublic": 1,
            "email": "fhaag@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 6,
                    "name": "Mission",
                    "slug": "mission",
                    "description": "Quia nesciunt nemo sequi. Et id tempore animi. Sed quis vel animi nemo ducimus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 28,
                        "category_id": 6
                    }
                }
            ]
        },
        {
            "id": 29,
            "programCode": "7079",
            "title": "Sint ut quae dolor ut.",
            "slug": "sint-ut-quae-dolor-ut",
            "description": "Autem sint earum nihil officia. Consequatur illum esse eum.",
            "checkHandler": "Prof. Dorothy Conroy V",
            "eventIncharge": "Ms. Skyla Wolf",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-11-26 18:16:14",
            "price": 301,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 178,
            "isPublic": 1,
            "email": "joanne.kling@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Charity",
                    "slug": "charity",
                    "description": "Vel itaque dolorum a voluptatem ea nihil. Voluptatibus minima vel non culpa ex.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 29,
                        "category_id": 7
                    }
                }
            ]
        },
        {
            "id": 30,
            "programCode": "2684",
            "title": "Sit est nulla totam.",
            "slug": "sit-est-nulla-totam",
            "description": "Sed dicta nobis quia autem iure. Quam nesciunt nisi ea veniam distinctio dolorem.",
            "checkHandler": "Kirsten Lubowitz",
            "eventIncharge": "Miss Laurence Kessler II",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-08-19 13:28:44",
            "price": 565,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 361,
            "isPublic": 1,
            "email": "gbernier@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Charity",
                    "slug": "charity",
                    "description": "Vel itaque dolorum a voluptatem ea nihil. Voluptatibus minima vel non culpa ex.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 30,
                        "category_id": 7
                    }
                }
            ]
        },
        {
            "id": 31,
            "programCode": "1458",
            "title": "At ipsa esse non.",
            "slug": "at-ipsa-esse-non",
            "description": "Error et provident dolorem autem. Libero ducimus perferendis eligendi eos.",
            "checkHandler": "Boris Ebert",
            "eventIncharge": "Janick White",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-18 06:10:52",
            "price": 684,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 438,
            "isPublic": 1,
            "email": "grayson33@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Charity",
                    "slug": "charity",
                    "description": "Vel itaque dolorum a voluptatem ea nihil. Voluptatibus minima vel non culpa ex.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 31,
                        "category_id": 7
                    }
                }
            ]
        },
        {
            "id": 32,
            "programCode": "6926",
            "title": "Cumque qui quo eum.",
            "slug": "cumque-qui-quo-eum",
            "description": "Deleniti temporibus est sit velit aut et quia. Quia eveniet alias facere.",
            "checkHandler": "Delta Block",
            "eventIncharge": "Russell Kerluke",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-06-15 11:40:45",
            "price": 546,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 444,
            "isPublic": 1,
            "email": "henderson51@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 8,
                    "name": "Information Technology",
                    "slug": "information-technology",
                    "description": "Minima et temporibus vel consectetur eum. Aperiam sint culpa nemo corporis aut assumenda.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 32,
                        "category_id": 8
                    }
                }
            ]
        },
        {
            "id": 33,
            "programCode": "1283",
            "title": "Alias commodi est quod.",
            "slug": "alias-commodi-est-quod",
            "description": "Iste veritatis qui natus unde autem voluptas. Et labore voluptatem reiciendis impedit.",
            "checkHandler": "Elna Boyer",
            "eventIncharge": "Petra Lockman",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-02-22 20:01:38",
            "price": 446,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 16,
            "isPublic": 1,
            "email": "qeichmann@pfeffer.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 8,
                    "name": "Information Technology",
                    "slug": "information-technology",
                    "description": "Minima et temporibus vel consectetur eum. Aperiam sint culpa nemo corporis aut assumenda.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 33,
                        "category_id": 8
                    }
                }
            ]
        },
        {
            "id": 34,
            "programCode": "1406",
            "title": "Et beatae quas a.",
            "slug": "et-beatae-quas-a",
            "description": "Veniam eum consequatur repellendus voluptatem. Porro sapiente sed et qui voluptas vel.",
            "checkHandler": "Betty Jacobson PhD",
            "eventIncharge": "Shannon Lemke",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-03 18:26:09",
            "price": 169,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 70,
            "isPublic": 1,
            "email": "ewhite@stark.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 9,
                    "name": "Esse",
                    "slug": "esse",
                    "description": "Eaque quia ipsum enim natus eaque natus. Sed ut ut et est magnam dolorem sint.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 34,
                        "category_id": 9
                    }
                }
            ]
        },
        {
            "id": 35,
            "programCode": "6838",
            "title": "Eum totam et beatae qui.",
            "slug": "eum-totam-et-beatae-qui",
            "description": "Autem laborum fugit illo nemo. Voluptatibus quos itaque tempora esse eligendi eius et sit.",
            "checkHandler": "Mafalda Ritchie",
            "eventIncharge": "Devon Kautzer",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-05-24 11:19:12",
            "price": 435,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 183,
            "isPublic": 1,
            "email": "schmidt.erna@mitchell.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 10,
                    "name": "Eum Ipsam",
                    "slug": "eum-ipsam",
                    "description": "Error dolorem aliquam non commodi ut odio. Aut neque maxime qui. Vel molestiae aut quia.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 35,
                        "category_id": 10
                    }
                }
            ]
        },
        {
            "id": 36,
            "programCode": "7788",
            "title": "Ad enim quo commodi.",
            "slug": "ad-enim-quo-commodi",
            "description": "Occaecati dignissimos ad ipsam ut similique tempore. Alias et quis non id et.",
            "checkHandler": "Sylvan Ruecker",
            "eventIncharge": "Mrs. Clare Hoppe DVM",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-04-14 03:31:14",
            "price": 664,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 47,
            "isPublic": 1,
            "email": "henri69@mcdermott.net",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 10,
                    "name": "Eum Ipsam",
                    "slug": "eum-ipsam",
                    "description": "Error dolorem aliquam non commodi ut odio. Aut neque maxime qui. Vel molestiae aut quia.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 36,
                        "category_id": 10
                    }
                }
            ]
        },
        {
            "id": 37,
            "programCode": "1866",
            "title": "Et delectus enim dolor.",
            "slug": "et-delectus-enim-dolor",
            "description": "Repudiandae voluptatem delectus repellat et. Est voluptates est unde qui qui animi amet.",
            "checkHandler": "Miss Eliane Roberts",
            "eventIncharge": "Sylvester Jaskolski II",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-01-23 19:35:27",
            "price": 823,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 101,
            "isPublic": 1,
            "email": "bart85@nitzsche.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 6,
                    "name": "Mission",
                    "slug": "mission",
                    "description": "Quia nesciunt nemo sequi. Et id tempore animi. Sed quis vel animi nemo ducimus.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 37,
                        "category_id": 6
                    }
                }
            ]
        },
        {
            "id": 38,
            "programCode": "4769",
            "title": "Est at et tenetur nisi.",
            "slug": "est-at-et-tenetur-nisi",
            "description": "At consequatur eaque veniam. Ipsam omnis aut quis aut incidunt.",
            "checkHandler": "Mr. Arne Morissette DVM",
            "eventIncharge": "Ashlynn Lemke III",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-03-29 22:07:14",
            "price": 881,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 295,
            "isPublic": 1,
            "email": "hoppe.cayla@hotmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Charity",
                    "slug": "charity",
                    "description": "Vel itaque dolorum a voluptatem ea nihil. Voluptatibus minima vel non culpa ex.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 38,
                        "category_id": 7
                    }
                }
            ]
        },
        {
            "id": 39,
            "programCode": "6734",
            "title": "Est saepe non sequi.",
            "slug": "est-saepe-non-sequi",
            "description": "Aut voluptas fugit fugiat qui. Accusamus similique occaecati vero.",
            "checkHandler": "Marcellus Hackett",
            "eventIncharge": "Stefan Krajcik Sr.",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-06-17 11:41:00",
            "price": 413,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 138,
            "isPublic": 1,
            "email": "burnice43@yahoo.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 9,
                    "name": "Esse",
                    "slug": "esse",
                    "description": "Eaque quia ipsum enim natus eaque natus. Sed ut ut et est magnam dolorem sint.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 39,
                        "category_id": 9
                    }
                }
            ]
        },
        {
            "id": 40,
            "programCode": "2598",
            "title": "Sed quis amet quia enim.",
            "slug": "sed-quis-amet-quia-enim",
            "description": "Nulla ex excepturi voluptatem ipsam veritatis minima. Libero ipsa aut sint dolore consequatur.",
            "checkHandler": "Evangeline Gleason Jr.",
            "eventIncharge": "Carson Koch DDS",
            "schedule": "[{\"startDate\":\"2022-03-09\", \"startTime\":\"08:00\", \"endTime\": \"09:00\"}]",
            "activeUntil": "2022-04-13 22:35:25",
            "price": 251,
            "venue": "{\r\n\t\"location\": \"4980 Bechtelar Shoals Willmsville\",\r\n\t\"city\": \"UT\",\r\n\t\"country\": \"USA\",\r\n\t\"postalCode\": \"6014\"\r\n}",
            "limit": 412,
            "isPublic": 1,
            "email": "willms.charley@gmail.com",
            "specialSettings": "N/A",
            "type": "event",
            "isActive": 1,
            "status": 1,
            "thumbnail": "https://via.placeholder.com/450x300?text=Sower+Institute",
            "deleted_at": null,
            "created_at": "2022-06-20T10:07:49.000000Z",
            "updated_at": "2022-06-20T10:07:49.000000Z",
            "categories": [
                {
                    "id": 9,
                    "name": "Esse",
                    "slug": "esse",
                    "description": "Eaque quia ipsum enim natus eaque natus. Sed ut ut et est magnam dolorem sint.",
                    "deleted_at": null,
                    "created_at": "2022-06-20T10:07:49.000000Z",
                    "updated_at": "2022-06-20T10:07:49.000000Z",
                    "pivot": {
                        "event_id": 40,
                        "category_id": 9
                    }
                }
            ]
        }
]

export default {

    // get all events and courses
    async get() {
        // for connecting the backend
        // await Csrf.getCookie(); 
        // return Api.get('/all');

        return eventCourses
    },

    // get the selected event/course for profile view
    async profile(slug) {
        // await Csrf.getCookie(); 
        // return Api.get(`/profile/${slug}`);

        const event_course = eventCourses.find(eventCourse => eventCourse.slug == slug)
        return event_course

    },
    // get all events and courses
    async getAllWithPagination() {
        // await Csrf.getCookie();
        return Api.get('/events-courses-all');
    },
    // add new User
    async add(payload) {
        // await Csrf.getCookie(); 
        return Api.post('/events-course', payload);
    },

    // update event course
    async update(payload) {
        // await Csrf.getCookie(); 
        return Api.put('/events-course/'+payload.id, payload);
    },

     // delete event course
     async delete(payload) {
        // await Csrf.getCookie(); 
        return Api.delete('/events-course/'+payload);
    },

}