<template>
    <h6 class="fw-bold mb-2">Pay Now Payment</h6>
    <p>Pay using PayNow QR Code. Please indicate your full name and purpose of payment.</p>
    <div class="center">
        <img src="/images/test_qrcode.png" />
    </div>
</template>
<script>
    export default {
        name: 'paynow-payment'
    }
</script>