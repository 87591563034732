<template>
    <Navigation></Navigation>
    <div class="container">
        <div class="row">
            <div class="col s12">
                <h3 class="head-title">Categories</h3>
                <a class="waves-effect waves-light btn right"  @click="addNewCategories()">Add</a>
                <table class="responsive-table highlight">
                <thead>
                    <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="isloading">
                        <td colspan="17">
                            <div class="py-4 login100-form-btn center">
                                loading....
                            </div>
                        </td>
                    </tr>
                    <tr v-for="(course) in categories" :key="course.id" v-else>
                        <td>{{course.name}}</td>
                        <td>{{course.description}}</td>
                        <td>
                            <a class="waves-effect waves-light btn-small" @click="editCategories(course)"><i class="tiny material-icons">edit</i></a>&nbsp;
                            <a class="waves-effect waves-light btn-small red" @click="deleteCategories(course.id)"><i class="tiny material-icons">delete</i></a>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
        <!-- Modal Structure -->
        <div id="editModal" class="modal modal-fixed-footer modal1">
            <div class="modal-content">
                <h5>Update Categories</h5>
                <div class="row">
                    <br>
                    <form class="col s12">
                        <div class="row">
                            <div class="col s12"> 
                                <input v-model="form.id" id="first_name" type="hidden" class="validate">
                                <input placeholder="Title"  v-model="form.name" id="first_name" type="text" class="validate">
                            </div>
                        </div>    
                        <div class="row">
                            <div class="col s12"> 
                                <textarea contenteditable="true" v-model="form.description" ></textarea>
                            </div>
                        </div>                     
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat"  @click="submitUpdateCategories()">Update</a>
                &nbsp;
                <a class="modal-close waves-effect waves-green btn-flat">Close</a>
            </div>
        </div>
        <div id="addModal" class="modal modal-fixed-footer modal1">
            <div class="modal-content">
                <h5>Add Categories</h5>
                <div class="row">
                    <br>
                    <form class="col s12">
                         <div class="row">
                            <div class="col s12"> 
                                <input placeholder="Name"  v-model="form.name" type="text" class="validate">
                            </div>
                        </div>    
                         <div class="row">
                            <div class="col s12"> 
                                <textarea contenteditable="true" v-model="form.description" ></textarea>
                            </div>
                        </div> 
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat"  @click="submitAddCategories()">Add New</a>
                &nbsp;
                <a class="modal-close waves-effect waves-green btn-flat">Close</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Navigation from './../layout/Navigation';
    export default {
        components: {
            Navigation,
        },
        mounted: function() {
            // eslint-disable-next-line no-undef
            M.AutoInit();
            // eslint-disable-next-line no-undef
            $('select').formSelect();
        },
        data() {
            return {
                keyword: null,
                isloading: false,
                filter: ['title'],
                order: [10],	
                form: {
                    id:null,
                    name: null,	
                    description: null,
                }
            };
        },
        computed: {
            ...mapGetters({
                handleValidation: 'categories/handleValidation',
                handleError: 'categories/handleError',
                categories: 'categories/categories'
            })
        },
        methods: {
             ...mapActions({
                getCategories: 'categories/getCategories',
                delCategories: 'categories/delCategories',
                updateCategories: 'categories/updateCategories',
                addCategories: 'categories/addCategories',
            }),
        editCategories(data) {
            this.form.name = data.name? data?.name : '';
            this.form.description = data.description? data?.description : '';
            this.form.id = data.id;
            // eslint-disable-next-line no-undef
            $('#editModal').modal('open');
            // eslint-disable-next-line no-undef
            $('select').formSelect();
        },
        addNewCategories() {
            // eslint-disable-next-line no-undef
            $('#addModal').modal('open');
            // eslint-disable-next-line no-undef
            $('select').formSelect();
        },
        submitUpdateCategories() {
            this.updateCategories(this.form).then(()=> {
                // eslint-disable-next-line no-undef
                $('#editModal').modal('close');
                this.isloading = true;
                this.$toast.show(`Data successfully updated`);
            })
            .finally(() => {
                setTimeout(() => {
                    this.isloading = false;
                    this.getCategories();
                }, 2000)
            })
        },
        submitAddCategories() {
            this.addCategories(this.form).then(()=> {
                if (this.handleError?.status === 422) {
                    this.$toast.error(this.handleError.data.message);
                } else {
                    // eslint-disable-next-line no-undef
                    $('#addModal').modal('close');
                    this.isloading = false;
                     this.getCategories();
                    this.$toast.show(`Data successfully added`);
                }
            })
        },
        deleteCategories(id) {
            this.delCategories(id).then(()=> {
                this.isloading = true;
                this.$toast.show(`Data successfully deleted`);
            })
            .finally(() => {
                setTimeout(() => {
                    this.isloading = false;
                     this.getCategories();
                }, 2000)
            })
        }
        },
        created() {
            this.getCategories();
        },
    };
</script>
<style scoped>
    .cursor-icon {
        cursor: pointer;
    }
    input.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
        margin-left: 35px !important;
    }
    body {
        padding: 20px;
        text-align: center;
    }
</style>