import Category from "@/api/Category";

export default {
    namespaced: true,

    state: {
        categories: [],
        selectedCategory: null,
        handleError: null,
    },

    getters: {
        categories(state) {
            return state.categories
        },
        getSelectedCategories(state) {
            return state.selectedCategory
        },
        handleError(state) {
            return state.handleError
        },
    },

    actions: {

        getCategories({commit}) {
            return Category.getCategories()
                .then((response) => {
                    commit('CATEGORIES', response.data)
                })
        },

        addCategories({commit}, payload) {
            Category.add(payload)
                .then((response) => {
                    commit('CATEGORIES', response.data)
                })
                .catch((errors) => {
                    commit('HANDLE_ERROR', errors.response)
                })
        },

        updateCategories({commit}, payload) {

            Category.update(payload)
                .then((response) => {
                    commit('CATEGORIES', response.data)
                })
                .catch((errors) => {
                    commit('HANDLE_ERROR', errors.response)
                })
        },
        delCategories({commit}, payload) {

            Category.del(payload)
                .then((response) => {
                    commit('CATEGORIES', response.data)
                })
                .catch((errors) => {
                    commit('HANDLE_ERROR', errors.response)
                })
        },

    },

    mutations: {
        HANDLE_ERROR(state, payload) {
            state.handleError = payload;
        },

        CATEGORIES(state, payload) {
            state.categories = payload;
        },
    }

}