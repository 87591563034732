<template>
	<footer>
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <div class="row center">
                        <div class="col s4 m4 l4">
                            <h6 class="fw-bold grey-text text-darken-2">Course</h6>
                            <ul class="collection footer-menu-list">
                                <li class="collection-item"><a href="#!">Lorem ipsum dolor</a></li>
                                <li class="collection-item"><a href="#!">sit amet, consec-</a></li>
                                <li class="collection-item"><a href="#!">tetuer adipiscing</a></li>
                                <li class="collection-item"><a href="#!">elit, sed diam</a></li>
                            </ul>
                        </div>
                        <div class="col s4 m4 l4">
                            <h6 class="fw-bold grey-text text-darken-2">Resources</h6>
                            <ul class="collection footer-menu-list">
                                <li class="collection-item"><a href="#!">Lorem ipsum dolor</a></li>
                                <li class="collection-item"><a href="#!">sit amet, consec-</a></li>
                                <li class="collection-item"><a href="#!">tetuer adipiscing</a></li>
                                <li class="collection-item"><a href="#!">elit, sed diam</a></li>
                            </ul>
                        </div>
                        <div class="col s4 m4 l4">
                            <h6 class="fw-bold grey-text text-darken-2">Our Mission</h6>
                            <ul class="collection footer-menu-list">
                                <li class="collection-item"><a href="#!">Lorem ipsum dolor</a></li>
                                <li class="collection-item"><a href="#!">sit amet, consec-</a></li>
                                <li class="collection-item"><a href="#!">tetuer adipiscing</a></li>
                                <li class="collection-item"><a href="#!">elit, sed diam</a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div class="col s12 m6 l6">
                    <div class="row">
                        <div class="col s12 offset-s0 l9 offset-l3 m9 offset-m3">
                            <h5 class="mt-0">Contact Us</h5>
                            <button class="btn btn-large amber ligther-3 mt-1">EMAIL US NOW</button>
                            <ul class="collection footer-address">
                                <li class="collection-item">The Bible Society of Singapore</li>
                                <li class="collection-item">7 Armenian Street, Bible House, Singapore 179932</li>
                                <li class="collection-item">Phone: 6304 5286 | email: sowerinstitute@bible.org.sg.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col s12 center copyrightSection white-text">
                <small>Copyright 2022 - The Bible Society of Singapore</small>
            </div>
        </div>
    </footer>
</template>
<script>
	export default {
		name: "homepage-footer"
	}
</script>
<style scoped>
    footer {
        padding-top: 50px;
    }

    footer ul.footer-menu-list, footer ul.footer-address {
        border: none;
        margin-top: 35px;
    }

    footer ul.footer-menu-list .collection-item {
        border-bottom: none !important;
        padding-inline: 0px !important;
        padding-top: 5px;
    }

    footer ul.footer-menu-list .collection-item a {
        color: #343131;
        font-size: 13px;
    }

    footer ul.footer-menu-list .collection-item a:hover {
        color: #555;
    }

    footer ul.footer-address .collection-item {
        border-bottom: none !important;
        padding-inline: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 5px !important;
        font-size: 13px;
        color: #343131;
    }

    .copyrightSection {
        background: #c59640;
        padding-block: 15px !important;
        position: absolute;
    }
    
</style>