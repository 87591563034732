<template>
    <div class="container">
        <div class="row">
            <h5 class="fw-bold mb-2">Feedbacks & Comments</h5>
            <ul class="collection">
                <li class="collection-item avatar pblock-1">
                    <i class="material-icons circle green">person</i>
                    <span class="title fw-bold">Frederick W.</span> &nbsp;
                    <small class="grey-text ligthen-1">(a week ago)</small>
                    <p class="grey-text ligthen-1 comment">Lorem Ipsum Dolor Lorem Ipsum DolorLorem Ipsum Dolor DolorLorem Ipsum DolorDolorLorem Ipsum Dolor. Lorem Ipsum Dolor Lorem Ipsum DolorLorem Ipsum Dolor DolorLorem Ipsum DolorDolorLorem Ipsum Dolor</p>
                    <!-- <a href="#!" class="secondary-content"><i class="material-icons">grade</i></a> -->
                </li>
                <li class="collection-item avatar pblock-1">
                    <i class="material-icons circle blue">person</i>
                    <span class="title fw-bold">Jason B.</span>&nbsp;
                    <small class="grey-text ligthen-1">(May 26, 2022)</small>
                    <p class="grey-text ligthen-1 comment">Lorem Ipsum Dolor Lorem Ipsum DolorLorem Ipsum Dolor DolorLorem Ipsum DolorDolorLorem Ipsum Dolor. Lorem Ipsum Dolor Lorem Ipsum DolorLorem Ipsum Dolor DolorLorem Ipsum DolorDolorLorem Ipsum Dolor</p>
                    <!-- <a href="#!" class="secondary-content"><i class="material-icons">grade</i></a> -->
                </li>
                <li class="collection-item avatar pblock-1">
                    <i class="material-icons circle red">person</i>
                    <span class="title fw-bold">Stephen C.</span>&nbsp;
                    <small class="grey-text ligthen-1">(June 1, 2022)</small>
                    <p class="grey-text ligthen-1 comment">Lorem Ipsum Dolor Lorem Ipsum DolorLorem Ipsum Dolor DolorLorem Ipsum DolorDolorLorem Ipsum Dolor. Lorem Ipsum Dolor Lorem Ipsum DolorLorem Ipsum Dolor DolorLorem Ipsum DolorDolorLorem Ipsum Dolor</p>
                    <!-- <a href="#!" class="secondary-content"><i class="material-icons">grade</i></a> -->
                </li>
                <li class="collection-item avatar pblock-1">
                    <i class="material-icons circle orange">person</i>
                    <span class="title fw-bold">Jillin B.</span>&nbsp;
                    <small class="grey-text ligthen-1">(2 days ago)</small>
                    <p class="grey-text ligthen-1 comment">Lorem Ipsum Dolor Lorem Ipsum DolorLorem Ipsum Dolor DolorLorem Ipsum DolorDolorLorem Ipsum Dolor. Lorem Ipsum Dolor Lorem Ipsum DolorLorem Ipsum Dolor DolorLorem Ipsum DolorDolorLorem Ipsum Dolor</p>
                    <!-- <a href="#!" class="secondary-content"><i class="material-icons">grade</i></a> -->
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
    .comment {
        font-size: 14px;
        line-height: 22px;
    }

</style>