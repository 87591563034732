<template>
    <Navigation></Navigation>
    <div class="container">
        <div class="row" v-if="cartItems.length!==0">
            <div class="col s12">
                <h4 class="fw-bold margin-top">Checkout</h4>
            </div>
            <div class="col s12">
                <!-- {{cartItems[0]}} -->
                <table class="striped highlight">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th class="text-center">Quantity</th>
                            <th class="text-center">Price</th>
                            <th class="text-right" style="padding-right:10px">Total</th>
                            <th class="text-center">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in cartItems" :key="index">
                            <td width="70%">
                                <div class="flex justify-content-between">
                                    <img :src="item.thumbnail" class="event-img" />
                                    <div class="event-meta">
                                        <router-link :to="{ path: `/event/${item.slug}`}">
                                            <h6 class="fw-bold">{{item.title}}</h6>
                                        </router-link>
                                        <a class="event-categories" href="" v-for="(category, index) in item.categories" :key="index">
                                            <small>{{category.name}}</small>
                                        </a>
                                        <p class="sub-details">{{item.description}}</p>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center" width="8%">
                                <input 
                                    @change.prevent="changeQuantity(item)" 
                                    min="1" 
                                    placeholder="Quantity" 
                                    type="number" 
                                    v-model="item.quantity" 
                                    class="validate text-center">
                            </td>
                            <td class="text-center">{{ item.price > 0 ? parsePrice(item.price) : 'FREE' }}</td>
                            <td class="text-right" style="padding-right:10px">{{ item.price > 0 ? getSubTotal(item.quantity, item.price) : 'FREE' }}</td>
                            <td class="text-center pinline-1">
                                <svg @click="removeItem(item)" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 close_btn" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="tbl-footer-border">
                        <tr>
                            <td colspan="3" class="pb-0">
                                <h6 class="fw-bold mb-0">Total</h6>
                            </td>
                            
                            <td class="text-right pb-0">
                                <h6 class="fw-bold mb-0">{{parsePrice(getGrandTotal(cartItems))}}</h6>
                            </td>
                            <td class="text-center">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="text-left pt-0">
                                Apply Promo Code
                            </td>
                            <td colspan="2" class="text-center pt-0">
                                <input placeholder="Promo Code" type="text" class="validate text-center">
                            </td>
                            <td class="text-right pt-0">
                                <h6>{{parsePrice(checkoutPromocode)}}</h6>
                            </td>
                            <td class="text-center">&nbsp;</td>
                        </tr>
                        <tr class="grandTotalCell">
                            <td colspan="3" class="pb-0">
                                <h6 class="fw-bold mb-0">Grand Total</h6>
                            </td>
                            
                            <td class="text-right pb-0">
                                <h6 class="fw-bold mb-0">{{parsePrice(getGrandTotal(cartItems) - checkoutPromocode)}}</h6>
                            </td>
                            <td class="text-center">&nbsp;</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="col s12 text-right margin-top">
                <button class="btn btn-large blue darker-1 waves-effect modal-trigger fw-bold" href="#proceedToPaymentModal">
                    Register and Pay
                </button>
            </div>
        </div>
        <div class="row" v-else>
            <div  class="col s12">
                <div class="page-wrapper">
                    <h3>Cart is Empty</h3>
                    <p>Please go to marketplace</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal of customer form for payment -->
    <customerPaymentForm></customerPaymentForm>
    <br />
    <br />
    <br />
    <hr />
    <HomepageFooter></HomepageFooter>
</template>

<script>
import Navigation from "./layout/Navigation"
import HomepageFooter from "./layout/Footer"
import { mapActions } from 'vuex'
import { formatPrice } from '../helper'
import customerPaymentForm from './utility/payment/customerForm'

import M from 'materialize-css'

export default {
    components: {
        Navigation,
        HomepageFooter,
        customerPaymentForm
    },
    data() {
        return {
            cartItems: [],
            grandTotal: 0,
            // event_img: 'images/background1.jpg',
            checkoutPromocode: 10
        }
    },
    created() {
        this.cartItems = JSON.parse(localStorage.getItem('cart'))
    },
    mounted() {
        // Init Materialize package
        M.AutoInit();
        
        // Set modal not to close when click outside
        M.Modal.init(document.querySelectorAll('.modal'), {
            dismissible: false
        });
    },
    methods: {
        ...mapActions({
            updateItemQuantity: 'cart/updateQuantityInCartCheckout',
            removeItemFromCart: 'cart/removeItemFromCart'
        }),
        parsePrice(value) {
            return formatPrice(value)
        },
        getSubTotal(qnty, amount) {
            return formatPrice(qnty*amount);
        },
        getGrandTotal(items) {
            let total
            let subTotal = []

            if(items.length > 0) {
                items.forEach((value) => {
                    total = (value.quantity*value.price)
                    subTotal.push(total)
                })
                return subTotal.reduce((a, b) => a+b)
            }
        },
        changeQuantity(item) {
            this.updateItemQuantity(item)
                .then(() => {
                        this.$toast.success('Cart item quantity updated successfully');
                    })
        },
        removeItem(item) {
            const index = this.cartItems.findIndex(value => value.id === item.id);
            if (index > -1) {
                let remainingCartItems = this.cartItems.filter(value => value.id !== item.id)
                this.removeItemFromCart(remainingCartItems)
                    .then(() => {
                        this.cartItems.splice(index, 1);
                        this.$toast.warning('Cart item removed successfully');
                })
            }
        }
    }
}
</script>
<style scoped>
    .sub-details {
        line-height: 15px;
        font-size: 14px;
    }
    .tbl-footer-border {
        border-top:2px dashed rgb(90, 88, 88);
    }
    .event-img {
        width: 130px;
        /* height: 85px; */
    }
    .event-meta {
        padding-inline: 10px;
    }
    .event-meta h6 {
        margin-top: 0px;
    }
    .event-categories {
        padding-inline: 10px;
        margin-right: 5px;
        padding-block: 2px;
        background: #058972;
        color: #fff;
        vertical-align: middle;
    }
    .grandTotalCell {
        border-top:3px dashed rgb(90, 88, 88);
    }
    .margin-top {
        margin-top: 50px;
    }
    .close_btn {
        color: red;
        cursor: pointer;
    }
    .page-wrapper {
        min-height: 375px;
        justify-content: center;
        padding-top: 70px;
        text-align: center;
    }
</style>