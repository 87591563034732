<template>
    <Navigation></Navigation>
    <div class="container">
        <div class="row">
            <div class="col s12">
                <h3 class="head-title">Users</h3>
                <table class="responsive-table highlight">
                <thead>
                    <tr>
                    <th colspan="2">User</th>
                    <th scope="col">Status</th>
                    <th scope="col">Location</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user,index) in users" :key="user.id">
                    <td><i class="small material-icons" :id="index">account_circle</i></td>
                    <td>{{user.niceName ? user.niceName : '-'}}<br>{{user.email}}</td>
                    <td>Active</td>
                    <td>{{user.firstName ? user.firstName : '-'}} {{user.lastName ? user.lastName : '-'}}</td>
                    <td>{{user.email}}</td>
                    <td><a class="waves-effect waves-light btn modal-trigger" @click="showModal" data-toggle="modal" data-target="#modal1">Contact</a></td>
                    <!-- <td><router-link v-bind:to="'/profile/' + user.id"><a class=""></a></router-link></td> -->
                    <td><i class="small material-icons">more_vert</i></td>
                    </tr>
                </tbody>
                </table>        
        </div>
  </div>

        <!-- <a class="waves-effect waves-light btn-large" @click="activateToaster()">Vue Toaster</a> -->
    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import Navigation from './layout/Navigation';

    export default {
        components: {
            Navigation,
        },
        mounted: function() {
            // eslint-disable-next-line no-undef
            $('.modal').modal();
        },
        data() {
            return {
                keyword: null,
                loadingLoggedIn: false,
                usersList: []
            };
        },

        computed: {
            ...mapGetters({
                currentUser: 'user/currentUser',
                users: 'user/users'
            }),
        },

        methods: {
             ...mapActions({
                getUsers: 'user/getUsers'
            }),
            activateToaster() {
                this.$toast.show(`Hey! I'm here`);
            }

        },

        created() {
            this.getUsers()
        },

    };

</script>
<style scoped>
    
    .head-title {
        margin-block: 45px;
    }
    .btn-custom-style {
        background: #dc3545;
        color: #fff;
    }
    .btn-custom-style:hover {
        opacity: 0.87;
        color: #fff;
    }
    .customBorder {
        border-top: 1px solid #dfdfdf !important;
        border-left: 1px solid #dfdfdf !important;
        border-right: 1px solid #dfdfdf !important;
        font-weight: bold;
    }
    .disabled-btn {
        background: #292728 !important;
    }

    .login100-form .text-danger {
        margin-left: 20px !important;
    }

    .error_login {
        border-bottom: 1px solid #dc3545 !important;
    }
/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}


/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #555555;}
input:-moz-placeholder { color: #555555;}
input::-moz-placeholder { color: #555555;}
input:-ms-input-placeholder { color: #555555;}

textarea::-webkit-input-placeholder { color: #555555;}
textarea:-moz-placeholder { color: #555555;}
textarea::-moz-placeholder { color: #555555;}
textarea:-ms-input-placeholder { color: #555555;}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    background-image: url('/images/login_bg.jpg');
    width: 100%;  
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;  
}

.container-login100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.65);
}

.wrap-login100 {
    width: 390px;
    border-radius: 10px;
    overflow: hidden;
    background: transparent;
}


/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
}

.login100-form-logo {
    font-size: 60px; 
    color: #333333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto;
}

.login100-form-title {
    font-size: 28px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    display: block;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    padding: 29px 0;
}

.input100 {
    font-size: 20px;
    color: #555555;
    line-height: 1.2;

    display: block;
    width: 100%;
    height: 50px;
    background: transparent;
    padding: 0 10px 0 40px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}


.input100:focus {
    padding-left: 60px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
    width: 100%;
    padding-inline: 35px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: bolder;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 160px;
    height: 50px;
    border-radius: 0px;

    background: #dc3545;

    position: relative;
    z-index: 1;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 21px;
    background-color: #555555;
    top: 0;
    left: 0;
    opacity: 0;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background: #b32230;
}


</style>