<template>
  <div id="featuredSlider">
    <Carousel :autoplay="4000">
        <Slide v-for="slide in 10" :key="slide">
            <div class="carousel__item" style="background:url('/images/background1.jpg')">
                <div class="container">
                    <div class="row">
                        <div class="col s12">
                            <h1 class="header center white-text header-title fw-bold">
                                LIFE Training 
                                A Suicide Prevention Workshop
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <h5 class="header center col s12 white-text header-sub-title mt-0">
                            Calling out all church and ministry leaders, volunteers, befrienders and everyone
                            who is keen on becoming a community carer to someone with suicide ideation.
                        </h5>
                    </div>
                    <div class="row center">
                        <a href="#" class="banner_register_btn btn-large waves-effect waves-light orange darken-1">Register Now</a>
                    </div>
                </div>
                <!-- <div class="parallax">
                    <img src="/images/background1.jpg" class="parallax-img" alt="Unsplashed background img 1" />
                </div> -->
            </div>
        </Slide>
    </Carousel>
  </div>
</template>

<script>
    import { Carousel, Slide } from 'vue3-carousel';
    export default {
        name: 'BannerCarousel',
        components: {
            Carousel,
            Slide,
        },

        created() {
            
        }

    }
</script>

<style>
    .header-title, .header-sub-title {
        text-shadow: 2px 2px 4px rgb(82 64 73 / 75%);
    }
    .parallax {
        background-color: #a8e9dd !important;
    }
    .parallax img {
        opacity: 0.9 !important;
    }
    #featuredSlider {
        min-height: 430px;
        margin-top: 16px;
    }

    #featuredSlider .carousel{
        height: inherit;
    }

    #featuredSlider .carousel__prev, 
    #featuredSlider .carousel__next {
        display: none !important;
    }

    #featuredSlider .carousel__item {
        height: 560px;
        width: 100%;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #featuredSlider .header-sub-title {
            font-size: 25px;
            line-height: 35px;
        }

    /* mobile view */
    @media only screen and (max-width : 414px) {
        #featuredSlider .carousel__item {
            height: 750px;
        }

        #featuredSlider .header-title {
            font-size: 50px;
        }
        #featuredSlider .header-sub-title {
            font-size: 30px;
            line-height: 40px;
        }

        #featuredSlider .banner_register_btn {
            display: block;
            font-size: 25px;
        }
    }
   
</style>