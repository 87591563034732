export function initialize(store, router) {

	router.beforeEach((to, from, next) => {

		const requiresAuth 	= to.matched.some(record => record.meta.requiresAuth);
		const guestOnly 	= to.matched.some(record => record.meta.guestOnly);
		const currentUser 	= store.state.user.currentUser;
		
		if(requiresAuth) {
			if(!currentUser) {
				next({
					path: "/login",
					query: { redirect: to.fullPath }
				});
			}
			else if (currentUser) {

				if(guestOnly) {
					next({
						path: "/dashboard",
						query: { redirect: to.fullPath }
					});
				}
				else {
					next();
				}
			}
			else {
				next();
			}
		} 
		else {
			next();
		}
	});

}

