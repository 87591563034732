<template>
    <Popper :hover="true" disableClickAway offsetDistance="-50" offsetSkid="0" placement="right">
        <div class="card z-depth-1 custom-card">
            <div class="card-image evetCourseThumbnail" >
                <!-- {{eventCourse.thumbnail}} -->
                <img :src="eventCourse.thumbnail" class="evetCourseThumbnail" />
            </div>
            
            <div class="event-card-content">
                <h6 class="event-title fw-bold mb-1">
                    <router-link :to="{ name: 'EventSingleView', params: { slug: eventCourse.slug } }">
                        {{eventCourse.title}}
                    </router-link>
                </h6>
                
                <div class="event-meta flex">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 27" fill="currentColor" style="width:25px;">
                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                    </svg>
                    <span style="padding-left: 5px;">
                        {{venueData.location}}, {{venueData.city}} {{venueData.postalCode}}, {{venueData.country}}
                    </span>
                </div> 
                <div class="event-meta flex pblock-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 27" fill="currentColor" style="width:15px;">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                    </svg>
                    <ul class="mblock-0">
                        <li v-for="(sched, index) in scheduleData" :key="index">
                            <span style="padding-top:5px; padding-left:5px;">
                                {{ dateFormat(sched.startDate) }} 
                                {{ sched.startTime }} - 
                                {{ sched.endTime }}
                            </span>
                        </li>
                    </ul>
                </div>  
                <div class="flex">
                    <a class="waves-effect waves-light btn d-block text-uppercase p-0" style="margin-right:5px;">
                        <small>{{eventCourse.limit}} {{eventCourse.limit > 1 ? 'Seats' : 'Seat'}}</small>
                    </a>
                    <a class="waves-effect amber accent-3 btn d-block p-0" 
                        :class="{ 'grey darken-2':eventCourse.price > 0, 'amber accent-3': eventCourse.price == 0  }">
                        {{ eventCourse.price > 0 ? priceFormat(eventCourse.price) : 'FREE' }}
                    </a>
                </div>
            </div>
        </div>

        <template #content>
            <div style="padding:20px 10px">
                <h6 class="event-title fw-bold">{{eventCourse.title}}</h6>
                <p class="event-meta pinline-1">
                    {{eventCourse.description}}
                </p>
                <div class="p-1">
                    <AddToCart :eventCourseData="eventCourse" styleClass="waves-effect waves-light btn d-block text-uppercase"></AddToCart>
                    <!-- <a class="btn-floating btn-small waves-effect waves-light blue darken-1"><i class="material-icons">favorite</i></a> -->
                </div>
            </div>
        </template>
    </Popper>
</template>

<script>

import moment from 'moment'
import Popper from "vue3-popper"
import AddToCart from './AddToCart'
import { formatPrice, scheduleFormatter, venueFormatter } from '../../helper'

export default {
    name: 'EventCourseCard',
    components: {
        Popper,
        AddToCart
    },
    props: {
        eventCourse: {
            type: Object
        }
    },
    data() {
        return {
            scheduleData: null,
            venueData: null,
            event_img: 'images/background1.jpg',
        }
    },
    created() {
        this.scheduleData = scheduleFormatter(this.eventCourse.schedule)
        this.venueData = venueFormatter(this.eventCourse.venue)
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('MMM D, YYYY')
        },
        priceFormat(value) {
            return formatPrice(value)
        }
    }



    
}
</script>

<style scoped>

    .custom-card {
        border-radius: 3%;
    }
    .evetCourseThumbnail {
        height: 152px;
        object-fit: cover;
        object-position: top;
    }

    .event-card-content {
        padding: 15px;
        text-align: left;
    }
    
    .event-title {
        font-size: 17px;
        height: 40px;
    }

    .event-title a {
        color:rgb(49, 49, 49) !important;
    }

    .event-title a:hover {
        color: rgb(139 135 135) !important;
        text-decoration: underline;
    }

    .event-description {
        font-size: 13px;
        line-height: 20px;
        color: #939090;
        /* height: 115px; */
    }

    .event-meta {
        font-size: 11px;
        color: #3d3c3c;
        margin-block: 0px;
        line-height: 15px;
    }

    .card-image img {
        border-radius: 8px 8px 0 0;
    }

    :deep(.popper) {
        --popper-theme-background-color: #ffffff;
        --popper-theme-background-color-hover: #ffffff;
        --popper-theme-text-color: #333333;
        --popper-theme-border-width: 1px;
        --popper-theme-border-style: solid;
        --popper-theme-border-color: #eeeeee;
        --popper-theme-border-radius: 5px;
        --popper-theme-padding: 10px;
        --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);

    }

</style>
