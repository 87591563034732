<template>
    <button @click="addAndCheckout()" class="btn white black-text btn-large d-block checkout_btn">
        <small>Checkout Now</small>
    </button>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'checkout-button',
    props: {
            eventCourseData: {
                type: Object,
            },
        },
    methods: {
        ...mapActions({
            checkoutCart: 'cart/checkoutCart'
        }),
        addAndCheckout() {
            this.checkoutCart(this.eventCourseData)
                .then((response) => {
                    if(response !== 'exist')
                        this.$toast.success('Item added to cart successfully');
                })
            setTimeout(() => {
                this.$router.push({ path: '/checkout' })
            }, 500)
        }
    }
}
</script>
