import { createStore } from 'vuex';

import User from './modules/User';
import eventCourse from './modules/EventCourse';
import Categories from './modules/Category';
import Cart from './modules/Cart';
import Customer from './modules/Customer';

export default createStore({
    modules:{
        user: User,
        course: eventCourse,
        categories: Categories,
        eventcourse: eventCourse,
        cart: Cart,
        customer: Customer
    }
})