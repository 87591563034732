// Trim the string content
export function contentStringTrimmer(content, maxWordLimit) {
    let defaultLimit = maxWordLimit !== 'undefined' ? maxWordLimit : 100
    let trimmedString = content.substr(0, defaultLimit)
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    return trimmedString+'...'

}

// Parsing the schedule Stringify JSON 
export function scheduleFormatter(scheduleArrayData) {
    return JSON.parse(scheduleArrayData)
}

// Parsing the venue Stringify JSON 
export function venueFormatter(venueObjData) {
    return JSON.parse(venueObjData)
}


// format currency
export function formatPrice(value) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
}
