<template>
    <h6 class="fw-bold mb-2">Check Payment</h6>
    <table>
        <tr>
            <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Bank Name</td>
            <td>Development Bank of Singapore (DBS)</td>
        </tr>
        <tr>
            <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Account Name</td>
            <td>John Dummy Person Doe</td>
        </tr>
        <tr>
            <td class="fw-500" style="border-right: 1px solid rgb(226 226 226);" width="20%">Account No.</td>
            <td>123213-32321-32131</td>
        </tr>
    </table>
    <br />
    <small class="red-text">
        **Please send the validated copy of your check deposit to test@bsstest.org.sg. For support & assistance,
        please contact 63-4223-3413 or support@bsstest.org.sg
    </small>
    <br />
</template>
<script>
    export default {
        name: 'check-payment'
    }
</script>