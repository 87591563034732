import Api from "./Api";
import Csrf from "./Csrf";

export default {

    // get all event/course category
    async get() {
        await Csrf.getCookie(); 
        return Api.get('/all');
    },
    // get all events and courses
    async getAllWithPagination() {
        await Csrf.getCookie();
        return Api.get('/events-courses-all');
    },
    // get all categories
    async getCategories() {
        await Csrf.getCookie();
        return Api.get('/categories');
    },
    // add new User
    async add(payload) {
        await Csrf.getCookie(); 
        return Api.post('/categories', payload);
    },

    // update event course
    async update(payload) {
        await Csrf.getCookie(); 
        return Api.put('/categories/'+payload.id, payload);
    },

     // delete event course
     async del(payload) {
        await Csrf.getCookie(); 
        return Api.delete('/categories/'+payload);
    },

}