<template>
   <div class="row" style="margin-bottom: 0px !important">
        <div class="col s12">
            <div class="flex justify-content-left">
                <h6 class="fw-bold">Pay secure using credit card</h6>
                <!-- <img src="/images/credit_card_logos.png" width="80" height="20" class="card_icons" /> -->
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="input-field col s12">
            <input id="cardNumber" type="text" class="validate" v-model="cardNumber">
            <label for="cardNumber">Credit Card Number</label>
        </div>
    </div>
    
    <div class="row">
        <div class="input-field col s12 m4 l4">
            <input id="card_expiry_month" type="text" class="validate" v-model="cardExpiryMonth">
            <label for="card_expiry_month">Expiry Month</label>
        </div>
        <div class="input-field col s12 m4 l4">
            <input id="card_expiry_year" type="text" class="validate" v-model="cardExpiryYear">
            <label for="card_expiry_year">Expiry Year</label>
        </div>
        <div class="input-field col s12 m4 l4">
            <input id="cardCvc" type="text" class="validate" v-model="cardCvv">
            <label for="cardCvc">CVV</label>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'stripe-card-payment',
    data () {
        return {
        };
    },
    computed: {
        ...mapGetters({
            customerCC: 'customer/customerCC'
        }),
        cardNumber: {
            get () {
                return this.$store.state.customer.customerCC.number
            },
            set (value) {
                this.$store.dispatch('customer/getCustomerCard', ['cardNumber', value])
            }
        },
        cardExpiryMonth: {
            get () {
                return this.$store.state.customer.customerCC.cardExpiryMonth
            },
            set (value) {
                this.$store.dispatch('customer/getCustomerCard', ['cardExpiryMonth', value])
            }
        },
        cardExpiryYear: {
            get () {
                return this.$store.state.customer.customerCC.expiryYear
            },
            set (value) {
                this.$store.dispatch('customer/getCustomerCard', ['cardExpiryYear', value])
            }
        },
        cardCvv: {
            get () {
                return this.$store.state.customer.customerCC.cvv
            },
            set (value) {
                this.$store.dispatch('customer/getCustomerCard', ['cardCvv', value])
            }
        },
    },

};
</script>