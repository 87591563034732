<template>
    <Navigation></Navigation>
    <div class="container">
        <div class="row">
            <div class="col s12">
                <h3 class="head-title">Users</h3>
                <table class="responsive-table highlight">
                <thead>
                    <tr>
                    <th scope="col" colspan="2">User</th>
                    <th scope="col">Status</th>
                    <th scope="col">Location</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user,index) in users" :key="user.id">
                    <td><i class="small material-icons" :id="index">account_circle</i></td>
                    <td>{{user.niceName ? user.niceName : '-'}}<br>{{user.email}}</td>
                    <td>Active</td>
                    <td>{{user.address ? user.address : '-'}} {{user.city ? user.city : '-'}}  {{user.postalCode ? user?.postalCode : '-'}}</td>
                    <td>{{user.phoneNumber}}</td>
                    <td><a class="tiny waves-effect waves-light btn" @click="showContact(user)">Contact</a></td>
                    <!-- <td><router-link v-bind:to="'/profile/' + user.id"><a class=""></a></router-link></td> -->
                    <td><i class="tiny material-icons curson-icon"  @click="editUser(user)">edit</i>
                    <i class="tiny material-icons  curson-icon"   @click="deleteUser(user)">delete</i></td>
                    </tr>
                </tbody>
                </table>
        </div>
  </div>
    <!-- Modal Structure -->
    <div id="showContact" class="modal modal-fixed-footer modal1">
        <div class="modal-content">
            <h5>Contact</h5>
            <div class="row">
                <br>
                <form class="col s12">
                    <div class="row">
                        <div class="input-field col s6">
                            <input :value="form.fullname" id="first_name" type="hidden" class="validate">
                            <input placeholder="Fullname" :value="form.fullname" id="first_name" type="text" class="validate">
                            <label for="first_name">Fullname</label>
                        </div>
                        <div class="input-field col s6">
                            <input placeholder="Email" :value="form.email" id="first_name" type="text" class="validate">
                            <label for="first_name">Email</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s6">
                            <input placeholder="Phone no." :value="form.contactno" id="first_name" type="text" class="validate">
                            <label for="first_name">Phone no.</label>
                        </div>
                        <div class="input-field col s6">
                            <input placeholder="Telephone no." :value="form.contactno" id="first_name" type="text" class="validate">
                            <label for="first_name">Telephone no.</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <a class="waves-effect waves-light btn">Update</a>&nbsp;
            <a class="modal-close waves-effect waves-green btn-flat red">Close</a>
        </div>
    </div>
    
    <!-- Modal Structure -->
    <div id="editModal" class="modal modal-fixed-footer modal1">
        <div class="modal-content">
            <h5>Edit</h5>
            <div class="row">
                <br>
                <form class="col s12">
                    <div class="row">
                        <div class="input-field col s12">
                            <label for="disabled">Fullname : </label>
                        </div>
                        <div class="input-field col s12">
                            <input disabled :value="form.fullname"  id="disabled" type="text" class="validate">
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <label for="disabled">Contact no. : </label>
                        </div>
                        <div class="input-field col s12">
                        <input disabled v-model="form.contactno"  id="disabled" type="text" class="validate">
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <label for="disabled">Email address : </label>
                        </div>
                        <div class="input-field col s12">
                            <input disabled v-model="form.email" id="disabled" type="text" class="validate">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
        </div>
    </div>
        <!-- <a class="waves-effect waves-light btn-large" @click="activateToaster()">Vue Toaster</a> -->
    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import Navigation from './../layout/Navigation';

    export default {
        components: {
            Navigation
        },        
        mounted: function() {
            // eslint-disable-next-line no-undef
            M.AutoInit();
        },
        data() {
            return {
                keyword: null,
                loadingLoggedIn: false,
                usersList: [],
                form: {
                    fullname: null,
                    contactno: null,
                    telno: null,
                    email: null,
                },
            };
        },

        computed: {
            ...mapGetters({
                currentUser: 'user/currentUser',
                users: 'user/users'
            }),
        },

        methods: {
             ...mapActions({
                getUsers: 'user/getUsers'
            }),
            activateToaster() {
                this.$toast.show(`Hey! I'm here`);
            },
            showContact(data) {
                this.form.fullname = data.firstName? data?.firstName : '' + ' ' + data.lastName? data?.lastName : '';
                this.form.contactno = data.phoneNumber;
                this.form.email = data.email;
                 // eslint-disable-next-line no-undef
                $('#showContact').modal('open');
            },
            editUser(data) {
                // this.form.fullname = data.firstName? data?.firstName : '' + ' ' + data.lastName? data?.lastName : '';
                // this.form.contactno = data.phoneNumber;
                // this.form.email = data.email;
                console.log(data);
                 // eslint-disable-next-line no-undef
                $('#editModal').modal('open');
            },
            deleteUser(data) {
                this.$toast.show(`User successfully deleted!`);
                console.log(data);
                // this.form.fullname = data.firstName? data?.firstName : '' + ' ' + data.lastName? data?.lastName : '';
                // this.form.contactno = data.phoneNumber;
                // this.form.email = data.email;
                //  // eslint-disable-next-line no-undef
                // $('#editModal').modal('open');
            },
            updateUser(data) {
                this.$toast.show(`User successfully deleted!`);
                console.log(data);
                // this.form.fullname = data.firstName? data?.firstName : '' + ' ' + data.lastName? data?.lastName : '';
                // this.form.contactno = data.phoneNumber;
                // this.form.email = data.email;
                //  // eslint-disable-next-line no-undef
                // $('#editModal').modal('open');
            }

        },

        created() {
            this.getUsers()
        },

    };

</script>
<style scoped>
    .cursor-icon {
        cursor: pointer;
    }
    .head-title {
        margin-block: 45px;
    }
    .btn-custom-style {
        background: #dc3545;
        color: #fff;
    }
    .btn-custom-style:hover {
        opacity: 0.87;
        color: #fff;
    }
    .customBorder {
        border-top: 1px solid #dfdfdf !important;
        border-left: 1px solid #dfdfdf !important;
        border-right: 1px solid #dfdfdf !important;
        font-weight: bold;
    }
    .disabled-btn {
        background: #292728 !important;
    }

    .login100-form .text-danger {
        margin-left: 20px !important;
    }

    .error_login {
        border-bottom: 1px solid #dc3545 !important;
    }
/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}


/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #555555;}
input:-moz-placeholder { color: #555555;}
input::-moz-placeholder { color: #555555;}
input:-ms-input-placeholder { color: #555555;}

textarea::-webkit-input-placeholder { color: #555555;}
textarea:-moz-placeholder { color: #555555;}
textarea::-moz-placeholder { color: #555555;}
textarea:-ms-input-placeholder { color: #555555;}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    background-image: url('/images/login_bg.jpg');
    width: 100%;  
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;  
}

.container-login100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.65);
}

.wrap-login100 {
    width: 390px;
    border-radius: 10px;
    overflow: hidden;
    background: transparent;
}


/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
}

.login100-form-logo {
    font-size: 60px; 
    color: #333333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto;
}

.login100-form-title {
    font-size: 28px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    display: block;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    padding: 29px 0;
}

.input100 {
    font-size: 20px;
    color: #555555;
    line-height: 1.2;

    display: block;
    width: 100%;
    height: 50px;
    background: transparent;
    padding: 0 10px 0 40px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}


.input100:focus {
    padding-left: 60px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
    width: 100%;
    padding-inline: 35px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: bolder;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 160px;
    height: 50px;
    border-radius: 0px;

    background: #dc3545;

    position: relative;
    z-index: 1;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 21px;
    background-color: #555555;
    top: 0;
    left: 0;
    opacity: 0;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background: #b32230;
}


</style>