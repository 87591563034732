export default {
    namespaced: true,

    state: {
        customerFormValidation: [],
        customer: {},
        customerCC: {
            number: '',
            expiryMonth: '',
            expiryYear: '',
            cvv: ''
        }
    },

    getters: {
        customer(state) {
            return state.customer
        },
        customerCC(state) {
            return state.customerCC
        },
        customerFormValidation(state) {
            return state.customerFormValidation
        }
    },

    actions: {
        getCustomerFormData({commit}, payload) {
            commit('CUSTOMER', payload)
        },
        getCustomerCard({commit}, payload) {
            console.log(payload[0], payload[1])
            commit('GET_CARD_DETAILS', payload)
        }
    },

    mutations: {
        CUSTOMER(state, payload) {
            state.customer = payload
        },
        CUSTOMER_FORM_VALIDATION(state, payload) {
            state.customerFormValidation = payload
        },
        CUSTOMERCC(state, payload) {
            state.customerCC = payload
        },
        GET_CARD_DETAILS(state, payload) {
            if(payload[0] === 'cardNumber')
                state.customerCC.number = payload[1]
            else if(payload[0] === 'cardExpiryMonth')
                state.customerCC.expiryMonth = payload[1]
            else if(payload[0] === 'cardExpiryYear')
                state.customerCC.expiryYear = payload[1]
            else if(payload[0] === 'cardCvv')
                state.customerCC.cvv = payload[1]
        }
    }

}