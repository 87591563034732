import { createApp } from "vue";
import store from "./store";
import router from "./router";
import Toaster from '@meforma/vue-toaster';
import Popper from "vue3-popper";
import App from "./App.vue";
import { initialize } from './init';

import 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import '@vuepic/vue-datepicker/dist/main.css'

initialize(store, router);
    
const app = createApp(App);

app.config.productionTip = false;

app.use(router);
app.use(store);
app.use(Toaster);
app.component("Popper", Popper);
app.mount('#app');

app.config.devtools = true;