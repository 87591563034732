<template>
    <Navigation></Navigation>
    <div class="container">
        <div class="row">
            <div class="col s12">
                <div class="page-wrapper">
                    <h3>PAGE NOT FOUND</h3>
                    <p>Please contact administrator</p>
                </div>
            </div>
        </div>
    </div>
    <br />
    <br />
    <br />
    <hr />
    <HomepageFooter></HomepageFooter>
</template>

<script>
import Navigation from "../layout/Navigation"
import HomepageFooter from "../layout/Footer"

export default {
    components: {
        Navigation,
        HomepageFooter
    },
    data() {
        return {
        }
    },
}
</script>
<style scoped>
    .page-wrapper {
        min-height: 375px;
        justify-content: center;
        padding-top: 70px;
        text-align: center;
    }
</style>